import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['label'];

  // Display filename in input field while
  // uploading files
  display(evt) {
    const fileName = evt.target.value.split('\\').pop();
    this.labelTarget.innerHTML = fileName;
  }
}
