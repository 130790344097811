import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'imagePreview', 'imageInput' ]

  toggleSelect(event) {
    const currentCheckbox = event.target
    const targetSelectId  = currentCheckbox.dataset.targetSelect
    const selectElement   = document.getElementById(targetSelectId)

    selectElement.disabled = !currentCheckbox.checked
    selectElement.setAttribute('required', currentCheckbox.checked)
  }

  uploadImage() {
    this.imageInputTarget.click()
  }

  previewImage(event) {
    this.imagePreviewTarget.src = URL.createObjectURL(event.target.files[0])
  }
}