import ApplicationController from './application_controller';
import axios from '../utils/axios';
import { globalEventBus, GlobalEvents } from '../utils/global_events';
import { fileToDataUrl } from '../utils/helpers';

export default class extends ApplicationController {
  static targets = ['mediaCapture', 'spinner', 'inputCapture', 'saveButton', 'documentCaptureOptions', 'documentCaptureOptionSelect']
  static values = { createUrl: String, updateUrl: String, documentType: String, imagePlaceholder: String }

  connect() {
    this.updateMode = this.updateUrlValue.length > 0
    this.spinnerTarget.hidden = true
    this.documentCaptureOptionSelectTarget.hidden = false
    this.documentCaptureOptionsTarget.hidden = true
    this.imageData = null
    this.saveButtonTarget.disabled = true
    this.retry = 0
    this.exceedMaxFileSize = false

    Object.values(GlobalEvents.imageCapture).forEach((event) => {
      globalEventBus.subscribe(event, this.handleImageCapture.bind(this));
    })

    Object.values(GlobalEvents.fileUpload).forEach((event) => {
      globalEventBus.subscribe(event, this.handleFileUploadEvent.bind(this));
    })
  }

  handleImageCapture(event) {
    switch (event.type) {
      case GlobalEvents.imageCapture.captured:
        this.exceedMaxFileSize = false
        this.saveButtonTarget.disabled = false
        this.imageData = event.image

        break;
      case GlobalEvents.imageCapture.removed:
        this.exceedMaxFileSize = false
        this.saveButtonTarget.disabled = true
        this.imageData = event.image

        break;
    }
  }

  handleFileUploadEvent(event) {
    switch (event.type) {
      case GlobalEvents.fileUpload.uploaded:
        this.saveButtonTarget.disabled = false
        this.exceedMaxFileSize = event.exceededMaxSize
        const setImageData = this.setImageData.bind(this)

        fileToDataUrl(event.target.files[0], setImageData)

        break;
      case GlobalEvents.fileUpload.removed:
        this.exceedMaxFileSize = false
        this.imageData = null
        this.saveButtonTarget.disabled = true

        break;
    }
  }

  setImageData(data) {
    this.imageData = data
  }

  showCaptureOptions() {
    this.documentCaptureOptionSelectTarget.hidden = true
    this.documentCaptureOptionsTarget.hidden = false
  }

  selectFileCapture() {
    this.showCaptureOptions()
    this.mediaCaptureTarget.hidden = true
    this.inputCaptureTarget.hidden = false
  }

  selectMediaCapture() {
    this.showCaptureOptions()
    this.mediaCaptureTarget.hidden = false
    this.inputCaptureTarget.hidden = true
  }

  save(event) {
    event.preventDefault()

    this.spinnerTarget.hidden = false
    this.saveButtonTarget.disabled = true
    this.inputCaptureTarget.hidden = true
    this.mediaCaptureTarget.hidden = true

    this.saveDocument()
  }

  saveDocument() {
    const url = this.updateMode ? this.updateUrlValue : this.createUrlValue
    const method = this.updateMode ? 'PATCH' : 'POST'

    axios({
      method: method,
      url: url,
      data: { image: this.imageData, type: this.documentTypeValue, exceeded_max_size: this.exceedMaxFileSize }
    }).then(resp => {
      toastr.success(resp.data.message, 'Success')
      window.location.href = resp.data.redirect_url
    }).catch(resp => {
      const maxRetry = this.exceedMaxFileSize ? 0 : 3

      if (this.retry < maxRetry) {
        this.retry++
        this.saveDocument()

        return
      } else {
        this.retry = 0
        this.imageData = null
        this.spinnerTarget.hidden = true
        this.saveButtonTarget.disabled = true
        this.documentCaptureOptionSelectTarget.hidden = false
        this.documentCaptureOptionsTarget.hidden = true

        toastr.error(resp.response.data.error, 'Error')
      }
    });
  }
}