import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['SendToCompany', 'SendToUsers']
  
  recipientSelect(e) {
    var selectValue = e.currentTarget.value;
    this.resetForm();

    if (selectValue == 'send_to_owner_company') {
      this.initializeCompanyForm()
    } else if(selectValue == 'send_to_users') {
      this.initializeUsersForm()
    }
  }

  resetForm() {
    $(this.SendToCompanyTarget).addClass('hidden');
    $(this.SendToUsersTarget).addClass('hidden');
  }

  initializeCompanyForm() {
    $(this.SendToCompanyTarget).removeClass('hidden');
  }

  initializeUsersForm() {
    $(this.SendToUsersTarget).removeClass('hidden');
  }
}