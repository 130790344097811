import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['settingValue', 'feeValue', 'feeInterval', 'paidAccess']
  static values = { url: String }

  saveChanges(event) {
    event.preventDefault()

    $.ajax({
      type: 'PUT',
      url: this.urlValue,
      dataType: 'json',
      data: {
        enterprise_settings: {
          is_paid_access: this.paidAccessTarget.checked,
          enterprise_features: this.features(),
          enterprise_fee_attributes: {
            id: this.feeValueTarget.dataset.feeId,
            value: this.feeValueTarget.value,
            interval: this.feeIntervalTarget.value
          }
        }
      },
      success: (resp) => {
        toastr.success(resp.message)
        location.reload()
      },
      error: (resp) => { toastr.error(resp.error) }
    })
  }

  togglePaidAccess(event) {
    this.feeIntervalTarget.classList.toggle('disabled')
    this.feeValueTarget.classList.toggle('disabled')
  }

  features() {
    const result = {}

    _.each(this.settingValueTargets, (target) => {
      result[target.dataset.featureName] = {
        value: target.value,
        active: true
      }
    })

    return result
  }
}