import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  toggleActiveStatus(event) {
    if (event.target.checked) {
      $.ajax({
        type: 'PUT',
        url: `/company_admin/district_onboarding_workflows/${event.target.id}/make_active`,
        dataType: 'json',
        success: (resp) => { toastr.success(resp.message) },
        error: (resp) => { toastr.error(resp.responseJSON.errors) }
      })
    }
  }
}