import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['sliderDisplay', 'scoreDisplay', 'formValue']
  static values = { score: Object }

  connect() {
    this.slider = $(this.sliderDisplayTarget).slider(this.sliderOpts())
    this.slider.on('change', (event) => { this.updateScorevalue(event.value.newValue) })
  }

  sliderOpts() {
    return {
      min: -(this.scoreValue.minimum),
      max: this.scoreValue.maximum,
      step: 1,
      value: this.scoreValue.additional_score,
    }
  }

  updateScorevalue(newScoreValue) {
    this.currentScore = newScoreValue
    this.scoreDisplayTarget.innerText = this.currentScore

    if (this.hasFormValueTarget) {
      this.formValueTarget.value = this.currentScore
    }
  }
}