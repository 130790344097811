import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['input', 'toggle']
  static values = { content: String, shouldAskForPassword: Boolean, confirmPasswordModal: String }

  hideClass = 'fa-eye';
  showClass = 'fa-eye-slash';

  connect() {
    if (this.hasToggleTarget) {
      this.ssn = this.contentValue.split('-');
      this.ssn = this.ssn.map((val, index, arr) => {
        let returnVal = val

        if ((index !== arr.length - 1) || (index === arr.length - 1)) {
          returnVal = val.replaceAll(/\d/g, 'X')
        }

        return returnVal
      })

      this.maskedContent = this.ssn.join('-')
      this.hidden = false

      this.hide();
    }
  }

  shouldAskForPasswordValueChanged() {
    this.toggle();
  }

  confirmPasswordModalId() {
    if (this.hasConfirmPasswordModalValue) {
      return this.confirmPasswordModalValue
    } else {
      return 'confirmPasswordModal'
    }
  }

  toggle(event) {
    if (event) { event.preventDefault() }

    if (this.hidden) {
      if (this.shouldAskForPasswordValue) {
        $(`#${this.confirmPasswordModalId()}`).modal('show')
      } else {
        this.show()
      }
    } else {
      this.hide()
    }
  }

  show() {
    this.inputTarget.value = this.contentValue;

    this.toggleTarget.classList.add(this.hideClass)
    this.toggleTarget.classList.remove(this.showClass)

    this.hidden = false;
  }

  hide() {
    this.inputTarget.value = this.maskedContent;

    this.toggleTarget.classList.add(this.showClass)
    this.toggleTarget.classList.remove(this.hideClass)

    this.hidden = true;
  }
}