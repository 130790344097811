import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['referralField'];

  onValidateForm(event) {
    if (this.referralFieldTarget.value.length === 0) {
      event.preventDefault();
      toastr.error("Referral code is required.")
    }
  }
}