import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['answer']

  setAnswer(event) {
    const checkbox = event.target

    if (checkbox.checked) {
      this.answerTarget.value = checkbox.value
    } else {
      this.answerTarget.value = ''
    }
  }
}