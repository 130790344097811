import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['template', 'container']

  toggle(event) {
    switch (event.detail.state) {
      case 'add':
        if (!this.elementAdded) {
          this.containerTarget.insertAdjacentHTML('beforebegin', this.templateTarget.innerHTML)
          this.elementAdded = true
        }

        break
      case 'remove':
        if (this.elementAdded) {
          this.containerTarget.previousElementSibling.remove()
          this.elementAdded = false
        }

        break
    }
  }
}