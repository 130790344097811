import ApplicationController from './application_controller';
import axios from '../utils/axios';
import { ConfirmationModal } from '../utils/confirmation_modal';

export default class extends ApplicationController {
  static values = { url: String, confirmText: String }

  connect() {
    this.confirmModal = new ConfirmationModal({ text: this.confirmTextValue || 'Are you sure?' })
  }

  delete(event) {
    event.preventDefault()

    this.confirmModal.show().then(result => {
      if (result.value) {
        axios.delete(this.urlValue)
          .then(resp => {
            this.element.remove()
            const successMsg = resp.data.message || 'Deleted successfully.'
            toastr.success(successMsg, 'Success')
          })
          .catch(resp => {
            const errorMsg = resp.response.data.message || 'There was an error deleting the record.'
            toastr.error(errorMsg, 'Error')
          })
      }
    })
  }
}