import MarkerClusterer from '@googlemaps/markerclustererplus';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['map']
  static values  = { locations: Array }

  initialize() {
    this.config = {
      label: '1',
      markerCluster: {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
      }
    }
  }

  connect() {
    this.initializeMap()
  }

  googleMap() {
    return new google.maps.Map(
      this.mapTarget,
      {
        zoom: 5,
        center: { lat: 0, lng: 0 }
      }
    )
  }

  infoWindowContent(info) {
    let content = `
      <h4>${info.identifier}</h4><br>
      <span>${info.display_address}</span>
    `
    return content
  }

  infoWindow(content) {
    return new google.maps.InfoWindow({
      content: content,
    })
  }

  markers(map) {
    return this.locationsValue.map((location, i) => {
      let marker = new google.maps.Marker({
        position: location.coordinates,
        label: this.config.label
      })

      // Click to open label event
      // marker.addListener('click', () => {
      //   var content    = this.infoWindowContent({ identifier: location.identifier, display_address: location.display_address })
      //   var infoWindow = this.infoWindow(content)

      //   infoWindow.open(map, marker)
      // })

      return marker
    })
  }

  initializeMap() {
    let map     = this.googleMap()
    let markers = this.markers(map)
    let bounds  = new google.maps.LatLngBounds()

    markers.forEach((marker) => {
      bounds.extend(marker.position)
    })

    new MarkerClusterer(map, markers, { imagePath: this.config.markerCluster.imagePath })
    map.fitBounds(bounds)
  }
}