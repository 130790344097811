import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';
import { FilePreviewer } from '../utils/file_previewer';

export default class extends ApplicationController {
  static targets = ['form', 'replaceDocument', 'acknowledge', 'validCarrier', 'certificate', 'policyValidation', 'policyInformation', 'submitButton']

  connect() {
    this.currentCertificate = this.certificateTarget.src;
    this.submitButtonTarget.disabled = true;
    this.markAsInactive(false);

    Object.values(GlobalEvents.fileUpload).forEach((event) => {
      globalEventBus.subscribe(event, this.handleFileUploadEvent.bind(this));
    })

    globalEventBus.subscribe(GlobalEvents.toggle.checkbox, this.handleToggleCheckboxEvent.bind(this));
  }

  handleToggleCheckboxEvent(event) {
    switch (event.target.id) {
      case 'acknowledge':
        this.submitButtonTarget.disabled = !event.target.checked;
        break;
      case 'inactive':
        this.markAsInactive(event.target.checked);
        break;
    }
  }

  markAsInactive(isInactive) {
    this.inactive = isInactive;
    this.policyInformationTarget.hidden = this.inactive;
    this.acknowledgeTarget.hidden = this.inactive;
    this.replaceDocumentTarget.hidden = !this.inactive;

    if (!this.inactive && (this.certificateTarget.src !== this.currentCertificate)) {
      this.certificateTarget.src = this.currentCertificate;
    }
  }

  anyInvalid() {
    return _.some(this.policyValidationTargets, (target) => { return target.checked })
  }

  submit(event) {
    event.preventDefault()

    if (this.inactive) {
      this.policyValidationTargets.forEach((target) => {
        target.checked = false;
      });

      this.validCarrierTarget.value = false;
    } else {
      this.validCarrierTarget.value = !this.anyInvalid();
    }

    this.formTarget.submit()
  }

  handleFileUploadEvent(event) {
    switch (event.type) {
      case GlobalEvents.fileUpload.uploaded:
        const previewer = new FilePreviewer(event.target.files[0], this.certificateTarget);

        previewer.preview();
        this.acknowledgeTarget.hidden = false;
        break;
      case GlobalEvents.fileUpload.removed:
        this.certificateTarget.src = this.currentCertificate;
        this.acknowledgeTarget.hidden = true;
        break;
    }
  }
}