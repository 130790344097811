import ApplicationController from './application_controller';
import axios from '../utils/axios';
import { clone } from 'lodash';

export default class extends ApplicationController {
  static targets = ['descriptionNote', 'templateNote', 'noteList', 'newNote', 'noteListWrapper', 'noteListLoading', 'saveButon']

  notes = [];

  connect() {
    this.getNotes();
  }

  getNotes() {
    var _this = this;

    axios
      .get(Routes.note_todos_path({ format: 'json' }))
      .then((resp) => {
        _this.addNotes(resp.data);
      })
      .catch((error) => { console.error(error) });
  }

  addNotes(notes) {
    this.notes = notes;
    this.buildNoteListing();
  }

  buildNoteListing() {
    var _this = this;
    this.noteListTarget.innerHTML = '';
    
    if (this.notes.length > 0) {
      this.notes.forEach(function (note) {
        _this.appendNote(note.id, note.description, note.priority, note.position);
      });
    } else {
      this.noteListTarget.innerHTML = '<p>No Current Notes.</p>';
    }

    this.showListingNotes();
  }

  resetDisplay() {
    this.noteListWrapperTarget.classList.add('hidden');
    this.noteListLoadingTarget.classList.add('hidden');
    this.newNoteTarget.classList.add('hidden');
  }

  showListingNotes() {
    this.resetDisplay();
    this.noteListWrapperTarget.classList.remove('hidden');
  }

  showNewFormNote() {
    this.resetDisplay();
    this.newNoteTarget.classList.remove('hidden');
    this.saveButonTarget.removeAttribute('data-edit');
    this.descriptionNoteTarget.value = '';
  }

  closeNewFormNote() {
    this.resetDisplay();
    this.noteListWrapperTarget.classList.remove('hidden');
  }

  onClickAddNote() {
    this.showNewFormNote();
  }

  onCloseNewNote() {
    this.closeNewFormNote();
  }

  onClickEdit(e) {
    this.showNewFormNote();
    var $wrapper = e.currentTarget.closest('[data-note]');
    this.saveButonTarget.setAttribute('data-edit', $wrapper.getAttribute('data-note'));
    this.descriptionNoteTarget.value = $wrapper.querySelector('p').innerHTML;
  }

  onClickRemove(e) {
    var $wrapper = e.currentTarget.closest('[data-note]');
    var id = $wrapper.getAttribute('data-note');
    var _this = this;

    axios
      .delete(Routes.note_todo_path({ id, format: 'json' }))
      .then((resp) => {
        if (resp.data.success) {
          var index = _this.notes.findIndex(function(i) { return i.id == resp.data.id });
          _this.notes.splice(index, 1);
          _this.buildNoteListing();
        } else {
          toastr.error(resp.data.errors[0])
        }
      })
      .catch((error) => { toastr.error(error) });
  }

  submitNote(e) {
    var description = this.descriptionNoteTarget.value;
    var editId = e.currentTarget.getAttribute('data-edit');
    
    if (editId) {
      this.updateNote.bind(this)(description, editId);
    } else {
      this.createNote.bind(this)(description);
    }
  }

  onChangePriority(e) {
    var $wrapper = e.currentTarget.closest('[data-note]');
    var id = $wrapper.getAttribute('data-note');

    this.updateServiceNote({ priority: e.currentTarget.text.toLowerCase() }, id)
  }

  updateNote(description, id) {
    this.updateServiceNote({ description: description }, id)
  }
  
  updateServiceNote(params, id) {
    var _this = this;

    axios
      .patch(
        Routes.note_todo_path({ id, format: 'json' }), 
        {
          note_todo: params
        }
      )
      .then((resp) => {
        if (resp.data.success) {
          var selectedNote = _this.notes.find(function(i) { return i.id == resp.data.id })
          if (selectedNote) selectedNote.description = resp.data.description;
          if (selectedNote) selectedNote.priority = resp.data.priority;
          _this.buildNoteListing();
          _this.descriptionNoteTarget.value = '';
          _this.closeNewFormNote();
        } else {
          toastr.error(resp.data.errors[0])
        }
      })
      .catch((error) => { toastr.error(error) });
  }

  createNote(description) {
    var _this = this;

    axios
      .post(
        Routes.note_todos_path({ format: 'json' }), 
        {
          note_todo: { 
            description: description 
          }
        }
      )
      .then((resp) => {
        if (resp.data.success) {
          _this.notes.unshift({ id: resp.data.id, priority: resp.data.priority, description: resp.data.description });
          _this.buildNoteListing();
          _this.descriptionNoteTarget.value = '';
          _this.closeNewFormNote();
        } else {
          toastr.error(resp.data.errors[0])
        }
      })
      .catch((error) => { toastr.error(error) });
  }

  appendNote(id, description, priority, position) {
    var cloneNote = this.templateNoteTarget.content.cloneNode(true);
    
    cloneNote.querySelector('.js-dragDropWrapper').setAttribute('data-position', position);
    cloneNote.querySelector('.dashboard-note').setAttribute('data-note', id);
    cloneNote.querySelector('p').innerHTML = description;
    cloneNote.querySelector('.note-priority').classList.add(priority.toLowerCase());
    cloneNote.querySelector('.note-priority').innerHTML = priority;

    this.noteListTarget.appendChild(cloneNote);
  }

  onDropNote(e) {
    e.preventDefault();
    var position = e.dataTransfer.getData("position");
    var $fromWrapper = $("[data-position='" + position + "']");
    var $toWrapper = $(e.currentTarget)

    var $fromContent = $fromWrapper.html();
    var $toContent = $toWrapper.html();

    $fromWrapper.html($toContent);
    $toWrapper.html($fromContent);
    
    var params = [
      {
        id: $fromWrapper.find('.dashboard-note').data('note'),
        position: $fromWrapper.data('position')
      },
      {
        id: $toWrapper.find('.dashboard-note').data('note'),
        position: $toWrapper.data('position')
      }
    ]

    axios
      .post(Routes.switch_position_note_todos_path({ format: 'json' }), {
        switches: params
      })
      .then((resp) => {})
      .catch((error) => { toastr.error(error) });

  }

  allowDrop(e) {
    e.preventDefault();
  }

  onDragStart(e) {
    e.dataTransfer.setData("position", e.target.closest('.js-dragDropWrapper').getAttribute('data-position'));
  }
}