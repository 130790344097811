import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['template', 'addNestedFormButton', 'wrapperDuplicateForm', 'formItem']
  static values  = { maxNestedFormCount: Number, currentNestedFormCount: Number }

  connect() {
    let nestedIndexStore = {}
    
    this.formItemTargets.forEach(function(item) {
      const attr = JSON.parse(item.dataset.nestedOptions);
      nestedIndexStore = {...nestedIndexStore, ...attr};
    });

    this.nestedIndexStore = nestedIndexStore
  }

  addNestedForm(event) {
    event.preventDefault()

    const nestedFormIndex = Math.floor(Math.random() * 100000)
    const newNestedForm   = this.templateTarget.innerHTML.replace(/NestedFormIndex/g, nestedFormIndex)
    let $duplicateWrapperForm = null;

    try {
      $duplicateWrapperForm = this.wrapperDuplicateFormTarget;
    } catch (err) {

    }
    
    if ($duplicateWrapperForm) {
      $duplicateWrapperForm.insertAdjacentHTML('beforebegin', newNestedForm);
    } else {
      event.target.parentElement.insertAdjacentHTML('beforebegin', newNestedForm);
    }
    
    this.nestedIndexStore[nestedFormIndex] = { formId: `#new-nested-form-${nestedFormIndex}`, destroyFieldId: `#new-nested-form-hidden-field-${nestedFormIndex}` }
    this.currentNestedFormCount += 1
  }

  removeNestedForm(event) {
    event.preventDefault()

    const nestedFormIndex = this.nestedIndexStore[event.target.dataset.nestedFormIndex]
    const nestedFormId    = event.target.dataset.nestedFormId
    if (nestedFormId || nestedFormIndex) {
      const form          = nestedFormId === '' ? document.querySelector(`${nestedFormIndex.formId}`) : document.querySelector(`#edit-nested-form-${nestedFormId}`)
      const destroyField  = nestedFormId === '' ? document.querySelector(`${nestedFormIndex.destroyFieldId}`) : document.querySelector(`#edit-nested-form-hidden-field-${nestedFormId}`)
      form.hidden         = true
      destroyField.value  = true
  
      this.currentNestedFormCount -= 1
    }
  }

  currentNestedFormCountValueChanged(newValue, _oldValue) {
    if (!this.hasAddNestedFormButtonTarget) { return }
    
    if (newValue > this.maxNestedFormCountValue) {
      this.addNestedFormButtonTarget.classList.add('disabled')
    } else {
      this.addNestedFormButtonTarget.classList.remove('disabled')
    }
  }
}
