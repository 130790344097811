// Display a list of elements as a "slideshow"
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['slide', 'nextButton', 'prevButton']
  static values = { index: Number }
  static classes = ['disabledBtn']

  initialize() {
    this.prevButtonTarget.classList.add(this.disabledBtnClass)
    _.last(this.nextButtonTargets).classList.add(this.disabledBtnClass)

    this.showCurrentSlide()
  }

  next(event) {
    event.preventDefault()

    this.indexValue++
    this.showCurrentSlide()
  }

  previous(event) {
    event.preventDefault()

    this.indexValue--
    this.showCurrentSlide()
  }

  parentSubmitButton() {
    let $button = null;
    const $form = this.element.closest('form');

    if ($form && $form.querySelector('#continue_button')) {
      $button = $form.querySelector('#continue_button')
    }

    return $button;
  }

  showCurrentSlide() {
    this.slideTargets.forEach((element, index) => {
      element.hidden = index != this.indexValue
    })

    // disable submit button if not last step
    const isLastIndex = this.indexValue + 1 === this.slideTargets.length;

    if (this.parentSubmitButton()) {
      const $button = this.parentSubmitButton();
      $button.setAttribute('disabled', true);
      if (isLastIndex) { $button.removeAttribute('disabled') };
    }
  }
}