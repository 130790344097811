import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['input', 'error']
  static values = { url: String }

  connect() {
    this.clearErrors()
  }

  confirmPassword(e) {
    e.preventDefault()

    $.ajax({
      type: 'POST',
      url: this.urlValue,
      dataType: 'json',
      data: { password: this.inputTarget.value },
      success: (_resp) => {
        this.clearErrors()
        $(this.element).modal('hide')

        window.dispatchEvent(this.passwordConfirmed())
      },
      error: (resp) => {
        this.errorTarget.textContent = resp.responseJSON.error
      }
    })
  }

  passwordConfirmed() {
    return new CustomEvent('passwordConfirmed')
  }

  clearErrors() {
    this.errorTarget.textContent = ''
  }
}