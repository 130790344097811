import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['scheduleDateTime', 'localDateTime', 'filterByTag']

  connect() {
    this.setScheduleDateTime();
    this.initialSelect2();
    this.initialLocationFilter();
  }

  setScheduleDateTime() {
    var refTag = this.localDateTimeTarget;position
    var dateRef = new Date(refTag.value);
    this.scheduleDateTimeTarget.value = dateRef.toUTCString();
  }

  initialSelect2() {
    if ($('select').length > 0) {
      $('select').select2();
    }
  }

  clickSendgridPreview(e) {
    e.preventDefault();

    var $sendgridCollection = $("[data-collection]");
    var collection = $sendgridCollection.data().collection;
    var selected = collection.find(function(i) { return i.id === $sendgridCollection.val() });
    window.open(selected.thumbnail_url, '_blank');
  }

  initialLocationFilter() {
    this.toggleLocationField($("[data-egroupable-target='filterByTag']").filter(':checked').val());
  }

  changeLocationFilter(e) {
    this.toggleLocationField(e.currentTarget.value);
  }

  toggleLocationField(val) {
    var selected = val;
    
    if(selected == 'current_address' || selected == 'current_location') {
      $('#location').show();
      $('#position').hide();
      $('#zip_code').hide();
    } else if(selected == 'position') {
      $('#position').show();
      $('#location').hide();
      $('#zip_code').hide();
    } else if(selected == 'zip_code') {
      $('#zip_code').show();
      $('#location').hide();
      $('#position').hide();
    } else {
      $('#location').hide();
      $('#position').hide();
      $('#zip_code').hide();
    }
  }
}