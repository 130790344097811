import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['content']

  toggle(event) {
    event.preventDefault()
    this.caretIcon       = event.target
    this.targetContentId = this.caretIcon.getAttribute('data-id')

    this.contentTargets.forEach((el, _i) => {
      if (this.targetContentId === el.getAttribute('data-id')) {
        el.classList.toggle('hidden')
        this.caretIcon.classList.toggle('fa-chevron-down')
        this.caretIcon.classList.toggle('fa-chevron-up')
      }
    })
  }
}