// A helper for traditional form submission, for forms that are in modals
// disables submit and cancel buttons on form submit
// Usage:
// <div data-controller='form-modal-submit'>
//  <button data-form-modal-submit-target='cancelButton'>Cancel</button>
//  <button data-form-modal-submit-target='submitButton' data-action='click->form-modal-submit#submitForm'>Submit</button>
// </div>
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['cancelButton', 'submitButton']

  connect() {
    if (this.hasCancelButtonTarget) { this.cancelButtonTarget.disabled = false }
    if (this.hasSubmitButtonTarget) { this.submitButtonTarget.disabled = false }
  }

  submitForm() {
    if (this.hasCancelButtonTarget) { this.cancelButtonTarget.disabled = true }
    if (this.hasSubmitButtonTarget) { this.submitButtonTarget.disabled = true }
  }
}