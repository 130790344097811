import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['content'];

  connect() {
    
  }

  onViewResponse(e) {
    $('#right_modal').modal('show');

    try {
      this.contentTarget.innerText = JSON.stringify(JSON.parse(e.currentTarget.dataset.body), null, "\t");;
    } catch (error) {
      this.contentTarget.innerText = e.currentTarget.dataset.body;
    }
  }
}