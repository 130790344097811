const countPages = (pdfFile, callback) => {
  const reader = new FileReader()

  reader.onload = (event) => {
    const typedArray = new Uint8Array(event.target.result)

    if (isPdf(typedArray)) {
      pdfjsLib.getDocument(typedArray).promise.then(pdf => callback(pdf.numPages))
    } else {
      callback(0)
    }
  }

  reader.readAsArrayBuffer(pdfFile)
}

const isPdf = (bytes) => {
  return bytes[0] === 0x25 && bytes[1] === 0x50 && bytes[2] === 0x44 && bytes[3] === 0x46
}

export { countPages }