import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['label', 'label1']

  // Display filename in input field while
  // uploading files
  display(evt) {
    const fileName = evt.target.value.split('\\').pop();
    this.labelTarget.innerHTML = fileName;
  }

  displayGeneral(evt) {
    const filename = evt.target.value.split('\\').pop();
    $(evt.target).siblings('label').html(filename);
  }
}
