import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['bgcAuthorization', 'bgcDisclosure']
  static values = { urls: Object }

  showBgcDisclosure() {
    this.bgcAuthorizationTarget.hidden = true
    this.bgcDisclosureTarget.hidden = false
  }

  toggleAcceptButton(event) {
    const acceptButton = $(`#${event.currentTarget.dataset.targetAcceptButton}`)
    const declineButton = $(`#${event.currentTarget.dataset.targetDeclineButton}`)

    if (event.target.checked) {
      acceptButton.removeClass('disabled')
      declineButton.addClass('disabled')
    } else {
      acceptButton.addClass('disabled')
      declineButton.removeClass('disabled')
    }
  }

  acceptBgcAuthorization() {
    const data = {
      payload: { consented_to_background_check: true },
      url: this.urlsValue.agree_url
    }

    this.save(data)
      .then((_resp) => { this.showBgcDisclosure() })
      .catch((error) => { console.log(error) })
  }

  acceptBgcDisclosure() {
    const data = {
      payload: { consented_to_background_check_disclosure: true },
      url: this.urlsValue.agree_url
    }

    this.save(data)
      .then((resp) => { window.location.href = resp.redirect_url })
      .catch((_error) => {
        this.showBgcDisclosure()
      })
  }

  decline() {
    if (confirm('This means that you will not be able to complete the process. Are you sure you want to Decline?')) {
      const data = {
        payload: {},
        url: this.urlsValue.decline_url
      }

      this.save(data)
        .then((resp) => { window.location.href = resp.redirect_url })
        .catch((error) => { console.log(error) })
    }
  }

  save(data) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: 'POST',
        url: data.url,
        contentType: 'application/json',
        data: new URLSearchParams(data.payload).toString(),
        success: (resp) => { resolve(resp) },
        error: (resp) => { reject(resp) }
      })
    })
  }
}