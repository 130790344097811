import _ from 'lodash';
import { Controller } from 'stimulus';

export default class extends Controller {
  submitting() {
    window.dispatchEvent(this.submitButtonEvent('submitting'))
  }

  submitted() {
    window.dispatchEvent(this.submitButtonEvent())
  }

  disableSubmitButton() {
    window.dispatchEvent(this.submitButtonEvent('disable'))
  }

  enableSubmitButton() {
    window.dispatchEvent(this.submitButtonEvent('enable'))
  }

  hideToggleableElement() {
    window.dispatchEvent(this.toggleElement('hide'))
  }

  showToggleableElement() {
    window.dispatchEvent(this.toggleElement('show'))
  }

  addTemplateElement() {
    window.dispatchEvent(this.toggleTemplate('add'))
  }

  removeTemplateElement() {
    window.dispatchEvent(this.toggleTemplate('remove'))
  }

  updateCss(data) {
    const event = new CustomEvent('updateCss', { detail: { action: data.action, css: data.css, id: data.id } })

    window.dispatchEvent(event)
  }

  updateHtml(data) {
    const event = new CustomEvent('updateHtml', { detail: { html: data.html, id: data.id } })

    window.dispatchEvent(event)
  }

  removeHtml(data) {
    const event = new CustomEvent('removeHtml', { detail: { id: data.id } })

    window.dispatchEvent(event)
  }

  submitButtonEvent(state) {
    const detail = { state: state }

    return new CustomEvent('toggleSubmitButton', { detail: detail })
  }

  toggleElement(state) {
    const detail = { state: state }

    return new CustomEvent('toggleElement', { detail: detail })
  }

  toggleTemplate(state) {
    const detail = { state: state }

    return new CustomEvent('toggleTemplate', { detail: detail })
  }
}