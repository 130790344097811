import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['name', 'workflowId']
  static values = { category: Object }

  connect() {
    this.categoryRecord = this.categoryValue.record
    this.newRecord = this.categoryRecord.id === null
    this.url = this.newRecord ? Routes.company_admin_company_categories_path() : Routes.company_admin_company_category_path(this.categoryRecord.id)
  }

  payload() {
    return {
      company_category: {
        name: this.nameTarget.value,
        district_onboarding_workflow_id: this.workflowIdTarget.value
      }
    }
  }

  submit(event) {
    event.preventDefault()
    this.submitting()

    if (this.newRecord) {
      axios.post(this.url, this.payload())
        .then(_resp => { toastr.success(`Company Category ${this.nameTarget.value} successfully created!`) })
        .catch(_resp => { toastr.error(`Failed to create Company Category ${this.nameTarget.value}!`) })
        .finally(() => {
          this.submitted()
          window.location.reload()
        })
    } else {
      axios.put(this.url, this.payload())
        .then(_resp => { toastr.success(`Company Category ${this.nameTarget.value} successfully updated!`) })
        .catch(_resp => { toastr.error(`Failed to update Company Category ${this.nameTarget.value}!`) })
        .finally(() => {
          this.submitted()
          window.location.reload()
        })
    }
  }
}