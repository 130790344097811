import ApplicationController from './application_controller.js';
import Choices from 'choices.js';
import '../styles/custom/choices.scss'

export default class extends ApplicationController {
  static values = { all: Array }
  static targets = ['element']

  connect() {
    this.choices = new Choices(this.elementTarget, { removeItemButton: true, placeholderValue: 'Search' });
  }

  selectAll() {
    this.choices.setChoiceByValue(this.allValue);
  }

  clearAll() {
    this.choices.removeActiveItems();
  }
}