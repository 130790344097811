import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['source', 'actionLabel']

  copy(event) {
    event.preventDefault()

    const origText = this.actionLabelTarget.innerHTML
    const value = this.sourceTarget.value || this.sourceTarget.innerHTML

    this.actionLabelTarget.innerHTML = 'Copied!'

    navigator.clipboard.writeText(value)
    setTimeout(() => { this.actionLabelTarget.innerHTML = origText }, 1500)
  }
}