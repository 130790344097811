import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static values = { show: Boolean }

  connect() {
    if (this.showValue) {
      $(this.element).modal('show')
    }
  }
}