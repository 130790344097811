import { Gauge } from 'gaugeJS';
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['canvas', 'rating']
  static values = { score: Number }

  connect() {
    this.gauge = new Gauge(this.canvasTarget); // https://bernii.github.io/gauge.js/
    this.gauge.setOptions(this.gaugeOpts());
    this.gauge.maxValue = 7;
    this.gauge.setMinValue(0);
    this.gauge.animationSpeed = 1;
    this.gauge.set(this.scoreValue);
    this.ratingTarget.style = `color: ${this.colorMap()[this.scoreValue]}`
  }

  colorMap() {
    return {
      1: '#267432',
      2: '#39AE4B',
      3: '#6BA32E',
      4: '#D5D551',
      5: '#F0A150',
      6: '#DC6900',
      7: '#D91D1D',
    }
  }

  staticZones() {
    return _.map(this.colorMap(), (color, key) => {
      return { strokeStyle: color, min: (key - 1), max: parseInt(key) }
    })
  }

  gaugeOpts() {
    return {
      angle: 0,
      lineWidth: 0.4,
      radiusScale: 1,
      pointer: {
        length: 0.62,
        strokeWidth: 0.07,
        color: '#000000'
      },
      generateGradient: true,
      highDpiSupport: true,
      staticLabels: {
        font: '18px sans-serif',
        labels: [0, 1, 2, 3, 4, 5, 6, 7],
        color: '#000000',
        fractionDigits: 0
      },
      staticZones: this.staticZones(),
    }
  }
}