import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'readToa', 'toaCheckboxSection', 'declineButton', 'acceptButton' ]
  static values  = { declineUrl: String }

  connect() {
    this.toaCheckboxSectionTarget.hidden = true
  }

  ok() {
    this.toaCheckboxSectionTarget.hidden = false
    this.readToaTarget.hidden = true
    this.declineButtonTarget.classList.remove('disabled')
  }

  checkToa(event) {
    if (event.target.checked) {
      this.declineButtonTarget.classList.add('disabled')
      this.acceptButtonTarget.classList.remove('disabled')
    } else {
      this.acceptButtonTarget.classList.add('disabled')
      this.declineButtonTarget.classList.remove('disabled')
    }
  }

  decline(event) {
    event.preventDefault()

    if (confirm('This means that you will not be able to complete the process. Are you sure you want to Decline?')) {
      event.target.innerHTML = 'Declining...'
      event.target.classList.add('disabled')

      Rails.ajax({
        type: 'POST',
        url: this.declineUrlValue,
        contentType: 'application/json',
        data: new URLSearchParams({}).toString(),
        success: (resp) => {
          toastr.warning('You have declined the Terms of Acceptance.')
          setTimeout(() => {
            window.location.href = resp.redirect_url
          }, 1000)
        },
        error: (resp) => {
          toastr.error(resp.error)
          event.target.classList.remove('disabled')
        },
        complete: () => { event.target.innerHTML = 'Decline' }
      })
    }
  }
}