import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['insuranceVerification']

  connect() {
    this.initInsuranceChart();
    this.initUsdotChart();
    this.initSignupChart();
  }

  initInsuranceChart() {
    const $insuranceChart = document.getElementById('js-coiChart');

    if (Chart && $insuranceChart) {
      const statistics = JSON.parse($insuranceChart.getAttribute('data-statistics'));

      const insuranceConfig = {
        type: 'doughnut',
        data: {
          labels: [
            'Active',
            'Pending',
            'Inactive/Incorrect',
            'No COI'
          ],
          datasets: [{
            data: statistics,
            backgroundColor: [
              'rgb(9, 48, 107)',
              'rgb(10, 81, 156)',
              'rgb(33, 113, 181)',
              'rgb(124, 165, 228)'
            ],
            hoverOffset: 4
          }]
        }
      };

      new Chart($insuranceChart, insuranceConfig)
    }
  }

  initUsdotChart() {
    const $usdotChart = document.getElementById('js-usdotChart');

    if (Chart && $usdotChart) {
      const statistics = JSON.parse($usdotChart.getAttribute('data-statistics'));

      const usdotConfig = {
        type: 'pie',
        data: {
          labels: [
            'DOT Active',
            'Not DOT'
          ],
          datasets: [{
            data: statistics,
            backgroundColor: [
              'rgb(9, 48, 107)',
              'rgb(10, 81, 156)'
            ],
            hoverOffset: 4
          }]
        }
      };

      new Chart($usdotChart, usdotConfig);
    }
  }

  initSignupChart() {
    const $signupChart = document.getElementById('js-signupChart');

    if (Chart && $signupChart) {
      const statistics = JSON.parse($signupChart.getAttribute('data-statistics'));

      const signupChartConfig = {
        type: 'bar',
        data: {
          labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'December'],
          datasets: [{
            label: 'Sign up ' + new Date().getFullYear(),
            data: statistics,
            backgroundColor: [
              'rgb(10, 81, 156)',
              'rgb(10, 81, 156)',
              'rgb(10, 81, 156)',
              'rgb(10, 81, 156)',
              'rgb(10, 81, 156)',
              'rgb(10, 81, 156)',
              'rgb(10, 81, 156)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        }
      }

      new Chart($signupChart, signupChartConfig);
    }
  }
}