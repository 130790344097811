import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['pie']

  connect() {
    const circle = new CircularProgressBar('pie')

    this.pieTargets.forEach(element => {
      circle.initial(element)
    })
  }
}