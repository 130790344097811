import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';

export default class extends ApplicationController {
  static targets = ['element']
  static classes = ['selectedElement']
  static values = { showIfChecked: Boolean }

  toggleSelectedClass(event) {
    if (event.target.checked) {
      this.elementTarget.classList.add(this.selectedElementClass)
    } else {
      this.elementTarget.classList.remove(this.selectedElementClass)
    }
  }

  toggleVisibility(event) {
    if (event.target.checked) {
      this.elementTarget.hidden = !this.showIfCheckedValue
    } else {
      this.elementTarget.hidden = this.showIfCheckedValue
    }

    globalEventBus.publish(GlobalEvents.toggle.checkbox, { target: event.target })
  }
}
