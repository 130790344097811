import ApplicationController from './application_controller';
import { ConfirmationModal } from '../utils/confirmation_modal';
import { globalEventBus, GlobalEvents } from '../utils/global_events';
import { validFileSize, toMB } from '../utils/helpers';
import { countPages } from '../utils/pdf_utilities';

import '../styles/custom/file_upload.scss'

export default class extends ApplicationController {
  static targets = ['fileList', 'fileInput', 'template', 'uploadButton']
  static values = { autoUpload: Boolean, maxFileSize: Number, maxPdfPages: Number }

  connect() {
    this.canUploadMultiple = this.fileInputTarget.dataset.canUploadMultiple === 'true'
    this.confirmModal = new ConfirmationModal({ title: 'Upload the file?', text: 'The file will be uploaded immediately' });
    this.exceededMaxSize = false
    this.exceededMaxPdfPages = false
  }

  uploadFile(event) {
    event.preventDefault()

    this.fileInputTarget.click()
  }

  onFileUploaded(event) {
    this.exceededMaxSize = false
    this.exceededMaxPdfPages = false

    const list = this.fileListTarget
    const template = this.templateTarget

    _.each(event.target.files, (file) => {
      const content = template.content.cloneNode(true)
      const element = content.querySelector('.file-name')
      const fileErrors = content.querySelector('.file-errors')
      const pdfErrors = content.querySelector('.pdf-errors')

      const exceedsMaxPdfPages = (numOfPages) => {
        this.exceededMaxPdfPages = numOfPages > this.maxPdfPagesValue

        if (this.exceededMaxPdfPages) {
          pdfErrors.textContent = `${file.name} - ${numOfPages} pages (WARNING! EXCEEDS MAX PAGES OF ${this.maxPdfPagesValue} PAGES)`
          pdfErrors.classList.add('text-danger')
        }
      }

      if (!validFileSize(file, this.maxFileSizeValue)) {
        fileErrors.textContent = `${file.name} - ${parseInt(toMB(file.size))}MB (WARNING! EXCEEDS MAX SIZE OF ${this.maxFileSizeValue} MB)`
        fileErrors.classList.add('text-danger')
        this.exceededMaxSize = true
      }

      countPages(file, exceedsMaxPdfPages)

      element.textContent = file.name
      list.appendChild(content)
    })

    if (this.autoUploadValue) {
      var _this = this;

      this.confirmModal.show().then((result) => {
        if (result.value) { _this.element.closest('form').submit(); }
      })
    }

    this.uploadButtonTarget.disabled = !this.canUploadMultiple

    const data = {
      target: event.target,
      exceededMaxSize: this.exceededMaxSize,
      exceededMaxPdfPages: this.exceededMaxPdfPages,
      files: event.target.files
    }

    globalEventBus.publishEvent(GlobalEvents.fileUpload.uploaded, data)
  }

  removeFile(event) {
    event.target.parentElement.remove()
    this.uploadButtonTarget.disabled = false

    if (!this.canUploadMultiple) { this.fileInputTarget.value = '' }
    globalEventBus.publishEvent(GlobalEvents.fileUpload.removed, { target: event.target })
  }
}
