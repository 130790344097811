import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['removeNetworkModal']

  connect() {
    $(this.removeNetworkModalTarget).on('show.bs.modal', (event) => {
      const button = $(event.relatedTarget)
      this.networkId = button.data('network-id')
    })
  }

  removeNetwork(event) {
    event.preventDefault()

    $.ajax({
      type: 'DELETE',
      url: `/networks/${this.networkId}`,
      dataType: 'json',
      success: (_resp) => { toastr.success('Requesting remove to Company') },
      error: (_resp) => { toastr.error('Request can not be processed') },
      complete: () => {
        $(this.removeNetworkModalTarget).modal('hide')
        window.location.reload()
      }
    })
  }
}