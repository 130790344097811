// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "toggle"]
  static values = { content: String }

  hideClass = "fa-eye";
  showClass = "fa-eye-slash";

  connect() {
    if(this.hasToggleTarget) {
      this.maskedContent = this.contentValue.replaceAll(/\d/g, 'X');

      this.hidden = false;

      this.hide();
    }
  }

  toggle(e) {
    if(this.hidden) { this.show() } else { this.hide() }
  }

  show() {
    this.inputTarget.value = this.contentValue;

    this.toggleTarget.classList.add(this.hideClass)
    this.toggleTarget.classList.remove(this.showClass)

    this.hidden = false;
  }

  hide() {
    this.inputTarget.value = this.maskedContent;

    this.toggleTarget.classList.add(this.showClass)
    this.toggleTarget.classList.remove(this.hideClass)

    this.hidden = true;
  }
}
