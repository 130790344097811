import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['deactivate-url']

  ACTION_DEACTIVATE = 'deactivate';
  ACTION_DELETE = 'delete';
  ACTION_REACTIVATE = 'reactivate';

  confirmAttributes(action) {
    switch (action) {
      case this.ACTION_DEACTIVATE:
        return {
          swalAttributes: {
            title: 'Are you sure?',
            text: "You can reactivate back the account later",
            confirmButtonText: 'Deactivate'
          },
          buttonText: 'Deactivating ...',
          requestMethod: 'DELETE'
        }
      case this.ACTION_DELETE:
        return {
          swalAttributes: {
            title: 'Are you sure?',
            text: 'Your data will be deleted permantently',
            confirmButtonText: 'Delete'
          },
          buttonText: 'Deleting ...',
          requestMethod: 'DELETE'
        }
      case this.ACTION_REACTIVATE:
        return {
          swalAttributes: {
            title: 'Are you sure?',
            text: 'Your data will be reactivated back',
            confirmButtonText: 'Reactivate'
          },
          buttonText: 'Reactivating ...',
          requestMethod: 'POST'
        }
    }
  }

  confirm(button, url, action) {
    let confirmAttributes = this.confirmAttributes(action);
    
    Swal({
      ...confirmAttributes.swalAttributes,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(function(result) {
      if (result.value) {
        button.text = confirmAttributes.buttonText;
        button.setAttribute('disabled', true);

        $.ajax({ 
          url: url,
          method: confirmAttributes.requestMethod,
          success: function() {
            window.location.reload()
          }
        })
      }
    });
  }

  clickAction(event) {
    event.preventDefault();
    this.confirm(event.target, event.target.href, event.target.getAttribute('action'));
  }
}