import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values  = {
    workflowSteps: Array,
    enableEditing: Boolean
  }
  static targets = ['inputName', 'dropBucket']

  connect() {
    this.enableDelete            = this.enableEditingValue
    this.workflowStepsCollection = []
    this.prePopulatingSteps      = false

    if (this.withExistingWorkflowSteps()) {
      this.prePopulatingSteps = true
      this.populateWithExistingSteps(this.workflowStepsValue)
    }
  }

  withExistingWorkflowSteps() {
    return (this.hasWorkflowStepsValue && this.workflowStepsValue.length > 0)
  }

  createCustomEvent(eventData, dataTransferText) {
    const customEvent  = document.createEvent('Event')

    customEvent.data = eventData
    customEvent.dataTransfer = {
      getData: (text) => { return dataTransferText }
    }

    return customEvent
  }

  populateWithExistingSteps(existingSteps) {
    existingSteps.forEach(step => {
      const customEventData = {
        target: this.getTargetBucketElement(step.number),
        record: {
          id: step.id,
          number: step.number,
          assessmentId: step.assessment_id
        }
      }
      const customEvent = this.createCustomEvent(customEventData, step.step_type)

      this.drop(customEvent)
    })

    this.prePopulatingSteps = false
  }

  getWorkflowStep(element) {
    const id = element.dataset.recordId || element.id
    const workflowStep = this.workflowStepsCollection.find(step => (step.id === _.toNumber(id) || step.element_id === id))

    return workflowStep
  }

  updateWorkflowStepCollection(event, element, targetBucket) {
    const workflowStepData = {
      number: targetBucket.dataset.number,
      step_type: element.dataset.identifier,
      assessment_id: element.dataset.assessmentId,
      element_id: element.id,
      '_destroy': false
    }

    element.dataset.recordNumber = targetBucket.dataset.number

    if (this.prePopulatingSteps) {
      // For Edit operations
      element.dataset.recordId       = event.data.record.id
      workflowStepData.assessment_id = event.data.record.assessmentId

      this.workflowStepsCollection.push({ ...workflowStepData, id: event.data.record.id })
    } else {
      this.createOrUpdateWorkflowStep(element, workflowStepData)
    }
  }

  createOrUpdateWorkflowStep(element, newData) {
    const exWorkflowStep = this.getWorkflowStep(element)

    if (exWorkflowStep) {
      this.updateWorkflowStep(exWorkflowStep, newData)
    } else {
      this.workflowStepsCollection.push(newData)
    }
  }

  updateWorkflowStep(workflowStep, newData) {
    _.remove(this.workflowStepsCollection, workflowStep)

    workflowStep.step_type = newData.step_type
    workflowStep.number    = newData.number
    workflowStep._destroy  = newData._destroy

    this.workflowStepsCollection.push(workflowStep)
  }

  deleteStep(event) {
    event.preventDefault()

    const elementToDelete = document.getElementById(event.target.dataset.targetElementId)
    const workflowStep    = this.getWorkflowStep(elementToDelete)

    this.removeElement(elementToDelete, event.target)
    this.updateWorkflowStep(workflowStep, { ...workflowStep, _destroy: true })
  }

  drop(event) {
    event.preventDefault()

    const targetBucket = this.currentTargetBucket(event)
    const element      = this.getElementToDrop(this.currentElement(event))

    // drop bucket already has an element,
    // so lets prevent this drop
    if (this.noDrop(targetBucket)) { return }

    targetBucket.classList.add('no-drop')

    if (!targetBucket.classList.contains('drop-bucket-solid')) {
      targetBucket.classList.add('drop-bucket-solid')
    }

    targetBucket.appendChild(element)

    this.updateWorkflowStepCollection(event, element, targetBucket)
  }

  removeElement(element, target) {
    const targetBucket = this.getTargetBucketElement(element.dataset.recordNumber)

    this.clearDropBucket(targetBucket)
    element.remove()
    target.remove()
  }

  allowDrop(event) {
    event.preventDefault()
    const target = event.target

    if (this.noDrop(target)) {
      return
    } else {
      target.classList.add('drop-bucket-solid')
    }
  }

  dragLeave(event) {
    event.preventDefault()
    const target = event.target

    if (this.noDrop(target)) {
      return
    } else {
      target.classList.remove('drop-bucket-solid')
    }
  }

  // Refers to the element currently being dragged, dropped, or deleted
  currentElement(event) {
    const data = event.dataTransfer.getData('text')

    return document.getElementById(data)
  }

  // Refers to the container("bucket") where the currentElement will be dropped
  currentTargetBucket(event) { return (event.target || event.data.target) }

  getTargetBucketElement(stepNumber) { return document.getElementById(`bucket-${stepNumber}`) }

  dragstart(event) {
    const currentParent = event.currentTarget.parentElement

    // We are moving the element away from the current drop bucket
    // so lets mark this drop bucket as empty
    this.clearDropBucket(currentParent)

    event.dataTransfer.setData("text", event.target.id)
  }

  clearDropBucket(element) {
    if (element.classList.contains('no-drop')) {
      element.classList.remove('no-drop')
    }

    if (element.classList.contains('drop-bucket-solid')) {
      element.classList.remove('drop-bucket-solid')
    }
  }

  // tag the dragged element as a copy
  setUpElementCopy(element) {
    this.markElementAsNoDrop(element)

    const randomHex = Math.random().toString(36).substring(7)

    element.classList = 'copy'
    element.id        = `${element.id}_${randomHex}_copy`

    if (this.enableDelete) {
      const deleteIcon = $(element).find('.delete-icon')[0]
      deleteIcon.dataset.targetElementId = element.id
      deleteIcon.classList.remove('hidden-icon')
    }

    return element
  }

  noDrop(element) {
    return element.classList.contains('no-drop')
  }

  markElementAsNoDrop(element) {
    $(`#${element.id}`).siblings().addClass('no-drop')
    Array.from(element.children).forEach(element => element.classList.add('no-drop'))
  }

  isOriginalElement(element) {
    return !element.classList.contains('copy')
  }

  getElementToDrop(currentElement) {
    if (this.isOriginalElement(currentElement)) {
      const elementCopy   = currentElement.cloneNode(true)
      const copiedElement = this.setUpElementCopy(elementCopy)

      // Copying
      return copiedElement
    } else {
      // Moving
      return currentElement
    }
  }

  workflowPayload(data) {
    return {
      onboarding_workflow: {
        name: data.name,
        workflow_steps_attributes: this.workflowStepsCollection
      }
    }
  }

  saveWorkflow(event) {
    const payload = this.workflowPayload({ name: event.detail.form_data.workflow_name })
    const url     = event.detail.form_url
    const method  = event.detail.form_method

    Rails.ajax({
      type: method,
      url: url,
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        options.data = JSON.stringify(payload)

        return true
      },
      success: (response) => {
        window.location.href = response.redirect_url
        toastr.success(response.message)
      },
      error: (response) => {
        window.location.reload()
        toastr.error(response.errors)
      }
    })
  }
}
