import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['middleName', 'noMiddleName', 'ssn', 'internationalNoSsn']

  toggleNoMiddleName() {
    if(this.noMiddleNameTarget.checked) {
      this.middleNameTarget.setAttribute('disabled', true)
    }
    else {
      this.middleNameTarget.removeAttribute('disabled')
    }
  }

  toggleNoSsn() {
    if (this.internationalNoSsnTarget.checked) {
      this.setInternationalSsn()
      this.ssnTarget.setAttribute('disabled', true)
    } else {
      this.ssnTarget.value = ''
      this.ssnTarget.removeAttribute('disabled')
    }
  }

  setInternationalSsn() {
    this.ssnTarget.value = this.data.get('internationalSsn')
  }
}