import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['id', 'name', 'email', 'companyName', 'position', 'sponsoredScreening']

  downloadCsv(event) {
    event.preventDefault()
    this.submitting()

    const url = new URL(event.target.href)

    url.searchParams.set('id', this.idTarget.value)
    url.searchParams.set('name', this.nameTarget.value)
    url.searchParams.set('email', this.emailTarget.value)
    url.searchParams.set('company_name', this.companyNameTarget.value)
    url.searchParams.set('position', this.positionTarget.value)
    url.searchParams.set('sponsored_screening', this.sponsoredScreeningTarget.value)

    window.location.href = url
    this.submitted()
  }
}