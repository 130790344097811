import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { redirectUrl: String, redirectDelay: Number }
  static targets = ['message']

  connect() {
    setTimeout(() => {
      window.location.href = this.redirectUrlValue
    }, this.redirectDelayValue)
  }
}
