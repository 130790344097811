import { Controller } from 'stimulus';

export default class extends Controller {
  static values  = { link: String }
  static targets = [
    'content',
    'previewImage',
    'previewTitle',
    'previewDescription',
  ]

  connect() {
    this.link = this.linkValue
    this.contentTarget.hidden = true
    this.showPreview()
  }

  getPreview() {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        type: 'GET',
        url: `/link_preview`,
        data: new URLSearchParams({ url: this.link }).toString(),
        contentType: 'application/json',
        success: (response) => { resolve(response) },
        error: (response) => { reject(response) }
      })
    })
  }

  showPreview() {
    if (this.link.length > 0) {
      this.getPreview().then((resp) => {
        const linkPreview = resp.data

        this.contentTarget.hidden = false
        this.previewTitleTarget.innerText       = linkPreview.title
        this.previewDescriptionTarget.innerText = linkPreview.description
        this.previewImageTarget.setAttribute('src', linkPreview.image)
      }).catch((error) => {
        console.log(error)
      })
    }
  }

  handleLinkUrlInputChanges(event) {
    this.link = event.detail
    this.showPreview()
  }
}