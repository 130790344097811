import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['electronicSignature', 'manualSignature']

  connect() {
    this.electronicSignatureReady = !this.hasElectronicSignatureTarget
    this.manualSignatureReady     = !this.hasManualSignatureTarget

    this.toggleSubmitButton()
  }

  toggleSignatureState(event) {
    if (event.detail.type == 'electronic') {
      this.electronicSignatureReady = (event.detail.state == 'ready')
    } else if (event.detail.type == 'manual') {
      this.manualSignatureReady = (event.detail.state == 'ready')
    }

    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    const signaturesReady = this.electronicSignatureReady && this.manualSignatureReady

    if (signaturesReady) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }
}