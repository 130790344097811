import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['container']
  static values = { hiddenCssClass: String }

  open() {
    this.containerTarget.classList.remove(this.hiddenCssClassValue)
  }

  close() {
    this.containerTarget.classList.add(this.hiddenCssClassValue)
  }
}