import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['checkbox']

  connect() {
    const checkBox = this.getChecked()

    if (checkBox) {
      this.uncheckOthers(checkBox)
      this.toggleCorrectOption(checkBox)
    }
  }

  toggleCheckbox(event) {
    const checkBox = event.target

    if (checkBox.checked) {
      this.uncheckOthers(checkBox)
      this.toggleCorrectOption(checkBox)
    } else {
      this.toggleCorrectOption(checkBox)
    }
  }

  getChecked() {
    return _.find(this.checkboxTargets, checkbox => checkbox.checked)
  }

  uncheckOthers(checkboxElement) {
    this.checkboxTargets.forEach(checkbox => {
      if (checkbox !== checkboxElement) {
        checkbox.checked = false
        this.toggleCorrectOption(checkbox)
      }
    })
  }

  getTextArea(checkboxElement) {
    return checkboxElement.parentElement.parentElement.querySelector('textarea')
  }

  toggleCorrectOption(checkbox) {
    const textAreaElement = this.getTextArea(checkbox)
    if (checkbox.checked) {
      textAreaElement.classList.add('correct-option') }
    else {
      textAreaElement.classList.remove('correct-option')
    }
  }
}