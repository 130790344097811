import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['viewIt']

  connect() {
    this.hideToggleableElement()
    this.originalText = this.viewItTarget.innerHTML
  }

  documentViewed() {
    this.showToggleableElement()
    this.viewItTarget.innerHTML = 'View It Again'
  }
}