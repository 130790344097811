import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { container: String, containerId: Number, containerColumn: String }

  remove(event) {
    const fileIndex = event.target.dataset.fileIndex

    $.ajax({
      type: 'DELETE',
      url: `/file_uploads/${fileIndex}`,
      data: {
        file_container: this.containerValue,
        file_container_id: this.containerIdValue,
        file_container_column: this.containerColumnValue
      },
      dataType: 'json',
      success: (_resp) => { event.target.parentElement.remove() },
      error: (_resp) => { console.error("Failed to delete item") }
    })
  }
}