import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['websiteUrl', 'noWebsite']

  connect() {
    this.toggleNoWebsite()
  }

  toggleNoWebsite() {
    if (this.noWebsiteTarget.checked) {
      this.websiteUrlTarget.setAttribute('disabled', true)
      this.websiteUrlTarget.classList.add('disabled')
      this.websiteUrlTarget.value = ''
    } else {
      $('#district_website_url').removeAttr('disabled')
      this.websiteUrlTarget.classList.remove('disabled')
    }
  }
}