import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['input', 'icon']

  connect() {
    this.iconTarget.classList.add('fa-eye-slash')
  }

  toggle(event) {
    event.preventDefault()

    this.inputTarget.type = this.inputTarget.type === 'password' ? 'text' : 'password'

    if (this.inputTarget.type === 'password') {
      this.iconTarget.classList.add('fa-eye-slash')
      this.iconTarget.classList.remove('fa-eye')
    } else {
      this.iconTarget.classList.add('fa-eye')
      this.iconTarget.classList.remove('fa-eye-slash')
    }
  }
}