const validFileSize = (file, maxSize) => {
  return toMB(file.size) <= parseFloat(maxSize)
}

const toMB = (sizeInBytes) => {
  return sizeInBytes / 1024 / 1024
}

const fileToDataUrl = (file, callback) => {
  const reader = new FileReader()

  reader.onload = (event) => {
    callback(event.target.result)
  }

  reader.readAsDataURL(file)
}

export { validFileSize, toMB, fileToDataUrl }