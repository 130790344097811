import ApplicationController from "./application_controller";
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['videoPlayer'];

  connect() {
    this.addEventVideoJs()
  }

  addEventVideoJs() {
    const videoPlayerTarget = this.videoPlayerTarget;
    const player = videojs(videoPlayerTarget.getAttribute('id'));
    let status = videoPlayerTarget.getAttribute('status');
    const contentId = videoPlayerTarget.getAttribute('data-content-id');
    const url = this.element.dataset.url;
      
    player.on('timeupdate', function () {
      if (player.currentTime() > 1 && !status) {
        status = 'started';

        axios.post(url, { status: 'started' })
          .then(function() {
            videoPlayerTarget.setAttribute('status', 'started')
            $(".js-badgeContentStatus-" + contentId).html("<div class='badge badge-success'>Started</div>")
          });
      }

      if (player.currentTime() > 3 && (!status || (status && status == 'started'))) {
        status = 'running';
        axios.post(url, { status: 'running' })
          .then(function() {
            videoPlayerTarget.setAttribute('status', 'running')
            $(".js-badgeContentStatus-" + contentId).html("<div class='badge badge-success'>Running</div>")
          });
      }
    });

    player.on('ended', function () {
      if (!status || (status && ['running', 'started'].includes(status))) {
        status = 'completed';
        axios.post(url, { status: 'completed' })
        .then(function(response) {
          if (response && response.data.reload) {
            window.location.href = response.data.reload;
          } else {
            videoPlayerTarget.setAttribute('status', 'completed')
            $(".js-badgeContentStatus-" + contentId).html("<div class='badge badge-success'>Completed</div>")
            
            const $el = $(".js-badgeContentStatus-" + contentId);
            const $nextContentTag = $el.closest('[data-content-id]').next()
  
            $nextContentTag.find('.collapse').addClass('show');
            $nextContentTag.find('.js-buttonWatchContent').removeClass('hidden');
          }
        });
      }
    });
  }
}