import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['addDispatchInfoForm']

  onClickAddDispatchInfo(e) {
    this.addDispatchInfoFormTarget.action = Routes.super_admin_dispatch_companies_path({ vehicle_id: e.currentTarget.dataset.vehileId, dispatch_company_id: e.currentTarget.dataset.dispatchCompanyId });

    this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[initial_call_location]"]').value = null;
    this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[pickup_location]"]').value = null;
    this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[dropoff_location]"]').value = null;
    
    try {
      var modalTitle = this.addDispatchInfoFormTarget.closest('.modal-dialog').querySelector('.modal-title');
      modalTitle.innerText = modalTitle.dataset.title.replace("{{vin}}", e.currentTarget.dataset.vin).trim();
    } catch (err) {
      console.log(err)
    }
  }

  onClickEditDispatchInfo(e) {
    this.addDispatchInfoFormTarget.action = Routes.super_admin_dispatch_companies_path({ vehicle_id: e.currentTarget.dataset.vehileId, dispatch_company_id: e.currentTarget.dataset.dispatchCompanyId });
    var dispatchInfo = JSON.parse(e.currentTarget.dataset.dispatchInfo);
    
    if (dispatchInfo && dispatchInfo["Initial Call Location"] && dispatchInfo["Initial Call Location"] !== 'Not Available' ) {
      this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[initial_call_location]"]').value = dispatchInfo["Initial Call Location"];
    } else {
      this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[initial_call_location]"]').value = null;
    }

    if (dispatchInfo && dispatchInfo["Vehicle Pickup Location"] && dispatchInfo["Vehicle Pickup Location"] !== 'Not Available' ) {
      this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[pickup_location]"]').value = dispatchInfo["Vehicle Pickup Location"];
    } else {
      this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[pickup_location]"]').value = null;
    }

    if (dispatchInfo && dispatchInfo["Vehicle Dropoff Location"] && dispatchInfo["Vehicle Dropoff Location"] !== 'Not Available' ) {
      this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[dropoff_location]"]').value = dispatchInfo["Vehicle Dropoff Location"];
    } else {
      this.addDispatchInfoFormTarget.querySelector('input[name="vehicle_dispatch_information[dropoff_location]"]').value = null;
    }

    try {
      var modalTitle = this.addDispatchInfoFormTarget.closest('.modal-dialog').querySelector('.modal-title');
      modalTitle.innerText = modalTitle.dataset.title.replace("{{vin}}", e.currentTarget.dataset.vin).trim();
    } catch (err) {
      console.log(err)
    }
  }
}