import ApplicationController from "./application_controller";
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { url: String, configs: Object }
  static targets = ['multiSelect', 'selectDistrict'];

  connect() {
    $(this.multiSelectTarget)
      .multiselect({
        includeSelectAllOption: true,
        enableFiltering: true,
        maxHeight: 400,
        onChange: this.onChangeCategory.bind(this),
        templates: {
          filterClearBtn: `
            <span class="input-group-btn">
              <button class="btn btn-link multiselect-clear-filter" type="button">
                <i class="fa fa-times"></i>
              </button>
            </span>
          `
        }
      });
    
    $(document).on('change', "[data-controller='egroup-form'] .multiSelectCategory .multiselect-all input", this.onSelectAllChange.bind(this));
  }

  onSelectAllChange() {
    this.reloadDistricts();
  }

  onChangeCategory() {
    this.reloadDistricts();
  }
  
  reloadDistricts() {
    var base = this;
    var selected = $(this.multiSelectTarget).find("option:selected");
    
    if (selected && selected.length > 0) {
      var categoryIds = [];

      selected.each(function() {
        categoryIds.push(this.value)
      });

      axios.get(this.urlValue, { params: { company_category_ids: categoryIds }})
        .then((resp) => {
          base.reloadDistrictOptions(resp.data);
        })
        .catch((error) => { toastr.error(error) })
        .finally((_resp) => { this.submitted() })
    } else {
      base.reloadDistrictOptions([]);
    }
  }

  reloadDistrictOptions(collection) {
    var newOptions = "";

    $(this.selectDistrictTarget).find('option:selected').each(function() {
      $(this).prop('selected', false);
    });
    
    collection.forEach(function(i) {
      newOptions += `<option value='${i.id}'>${i.district_name}</option>`;
    });
    
    this.selectDistrictTarget.innerHTML = newOptions;
    $(this.selectDistrictTarget).multiselect('destroy');
    $(this.selectDistrictTarget).multiselect($(this.selectDistrictTarget).data('multiSelectConfigsValue'));
  }
}