import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['textField'];

  copyToClipboard() {
    let text = this.textFieldTarget.innerHtml;
    if (!text) text = this.textFieldTarget.value;
    
    navigator.clipboard.writeText(text);
    toastr.success("Copied to clipboard!")
  }
}