import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['changeRoleTitle', 'selectedRoleOptions', 'submitChangeRole'];
  static values = { userId: Number };

  connect() {
    this.initiateClickModal.bind(this)();
    this.selectedRoleOptionsTarget.addEventListener('change', this.onChangeRole.bind(this));
  }

  onChangeRole(e) {
    this.setTargetLink(this.userIdValue, e.currentTarget.value);
  }

  initiateClickModal() {
    var _this = this;
    
    $(document).on('click', '[data-target="#js-modalChangeRole"]', function(e) {
      var eTag = e.currentTarget;
      var email = eTag.dataset.email;
      var roleId = eTag.dataset.roleId;
      _this.userIdValue = parseInt(eTag.dataset.userId);
      
      _this.changeRoleTitleTarget.innerHTML = email;
      if (roleId) { _this.selectedRoleOptionsTarget.querySelector('option[value="' + roleId + '"]').setAttribute('selected', true); }
      _this.setTargetLink.bind(_this)(_this.userIdValue, _this.selectedRoleOptionsTarget.value);
    });
  }

  setTargetLink(userId, roleId) {
    this.submitChangeRoleTarget.setAttribute('href', Routes.assign_role_roles_path({ user_id: userId, role_id: roleId }))
  }
}