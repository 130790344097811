import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = ['initialShow', 'initialHide']

  onClickEye(e)  {
    e.preventDefault();
    
    if (this.initialShowTarget.classList.contains('hidden')) {
      this.initialShowTarget.classList.remove('hidden');
      this.initialHideTarget.classList.add('hidden');
    } else {
      this.initialShowTarget.classList.add('hidden');
      this.initialHideTarget.classList.remove('hidden');
    }
  }
}