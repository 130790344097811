import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { urls: Object };

  markAsPaid() {
    axios.put(this.urlsValue.mark_as_paid, {})
      .then((resp) => {
        this.updateHtml({ html: 'Yes', id: `${resp.data.invoice_id}-paid-status` });
        toastr.success(resp.data.message)
      })
      .catch((_resp) => { toastr.error('Cannot mark invoice as paid. Please try again.') });
  }

  delete() {
    axios.delete(this.urlsValue.delete)
      .then((resp) => {
        this.removeHtml({ id: `${resp.data.invoice_id}-row` });
        toastr.success(resp.data.message)
      })
      .catch((_resp) => { toastr.error('Cannot delete invoice. Please try again.') });
  }
}
