import ApplicationController from "./application_controller";
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['error', 'methodSelect', 'destination']

  connect() {
    this.clearErrors()
    this.employeeId = null

    $(this.element).on('show.bs.modal', (event) => {
      this.employeeId = event.relatedTarget.dataset.employeeId
    })

    $(this.element).on('hidden.bs.modal', (_event) => {
      this.destinationTarget.value = ''
      this.clearErrors()
    })
  }

  methodChanged(event) {
    let method = event.target.value

    if (method === 'sms') {
      this.destinationTarget.placeholder = 'Enter a valid Phone number'
    } else {
      this.destinationTarget.placeholder = 'Enter a valid email address'
    }
  }

  submit(event) {
    event.preventDefault()
    this.clearErrors()

    if ((this.methodSelectTarget.value === '') || (this.destinationTarget.value === '')) {
      this.errorTarget.textContent = 'Required field is missing'
      return
    }

    let payload = {
      send_method: this.methodSelectTarget.value,
      destination: this.destinationTarget.value
    }

    axios.post(Routes.send_profile_link_public_employee_path(this.employeeId), payload)
      .then(resp => {
        this.clearErrors()
        this.destinationTarget.value = ''

        toastr.success(resp.data.message)
        $(this.element).modal('hide')
      })
      .catch(resp => {
        toastr.error(resp.response.data.error)
      })
  }

  clearErrors() {
    this.errorTarget.textContent = ''
  }
}