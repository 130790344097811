// For clearing form inputs and reloading the form
// Useful for forms that are used as filters
// Usage:
// <form data-controller="form-clear">
//   <a href='#' data-action='click->form-clear#clearInputs'>Clear</a
// </form>
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.form = this.element
    this.formInputElements = _.filter(this.form.elements, (element) => {
      return (element.type !== 'hidden' && element.type !== 'submit')
    })
  }

  clearInputs(event) {
    event.preventDefault()
    this.formInputElements.forEach((element) => { element.value = '' })
    window.location.href = this.form.action
  }
}