import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { userId: Number }

  toggle(event) {
    const url = Routes.toggle_continous_monitoring_user_path(this.userIdValue)

    axios.put(url, { continously_monitor: event.target.checked })
      .then((resp) => {
        toastr.success(resp.data.message)
        this.removeHtml({ id: `${resp.data.user_id}-cml-row` })
      })
      .catch((_resp) => { toastr.error('Cannot update continous monitoring status of employee. Please try again.') });
  }
}