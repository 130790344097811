import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['riskRating', 'formSubmitButton', 'formCancelButton'];

  connect() {
    this.formSubmitButtonTarget.disabled = true;
  }

  selected() {
    if (this.selectedRiskRatings().length > 0) {
      this.formSubmitButtonTarget.disabled = false;
    } else {
      this.formSubmitButtonTarget.disabled = true;
    }
  }

  selectedRiskRatings() {
    const ratings = _.map(this.riskRatingTargets, (target) => {
      if (target.checked) { return target.value }
    });

    return _.compact(ratings);
  }

  submit(event) {
    event.preventDefault();
    this.formSubmitButtonTarget.innerText = 'Submitting...';
    const data = { risk_rating_categories: this.selectedRiskRatings() }

    Rails.ajax({
      type: 'POST',
      url: '/company_admin/risk_ratings',
      contentType: 'application/json',
      data: new URLSearchParams(data).toString(),
      error: (resp) => { console.table(resp) },
      complete: () => {
        this.formSubmitButtonTarget.innerText = 'Submit';

        window.location.reload();
      }
    })
  }
}