import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['fraudAlertModal', 'companyName', 'fraudNote']

  enrollRequest(event, options) {
    event.preventDefault();
    const crsfToken = $("[name='csrf-token']").attr('content');
    var el = event.currentTarget;
    var _options = options;
    el.classList.add('disabled')
    el.text = 'Processing ...'

    Swal({
      title: 'Are you sure?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(function(result){
      const params = { 
        authenticity_token: crsfToken
      };

      axios.post(`${el.href}.json`, params)
        .then(function(response) {
          if (response.data.success) {
            if (_options.refresh == true) {
              toastr.success('You have been enrolled to the training');
              window.location.reload();
            } else {
              toastr.success('You have been enrolled to the training');
              el.text = 'Enrolled'
            }
          } else {
            toastr.error('The request can not be processed');
            el.text = 'Enroll'
            el.classList.remove('disabled')
          }
        });
    })
  }

  onEnrollWithReload(e) {
    e.preventDefault();
    this.enrollRequest(e, { refresh: true});
  }

  onEnroll(e) {
    e.preventDefault();
    this.enrollRequest(e, { refresh: false });
  }
}