import ApplicationController from "./application_controller";
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { updateUrl: String }
  static targets = ['settingValue']

  async update(event) {
    event.preventDefault()

    try {
      const resp = await axios.put(this.updateUrlValue, { application_setting: { value: this.settingValueTarget.value } })
      const successMsg = resp.data.message || 'Updated successfully.'
      toastr.success(successMsg, 'Success')
    } catch (resp) {
      const errorMsg = resp.response.data.message || 'There was an error updating the record.'
      toastr.error(errorMsg, 'Error')
    }
  }
}