import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { configs: Object }

  connect() {
    $(this.element).datetimepicker({ ...this.defaultConfig(), ...this.configsValue })
  }

  defaultConfig() {
    return {
      format: 'MM/DD/YYYY'
    }
  }
}