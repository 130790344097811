import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['targetElement', 'antiTargetElement']

  toggleHandler(event) {
    switch (event.detail.state) {
      case 'hide':
        this.targetElementTargets.forEach(element => { element.hidden = true })

        if (this.hasAntiTargetElementTarget) {
          this.antiTargetElementTargets.forEach(element => { element.hidden = false })
        }

        break
      case 'show':
        this.targetElementTargets.forEach(element => { element.hidden = false })

        if (this.hasAntiTargetElementTarget) {
          this.antiTargetElementTargets.forEach(element => { element.hidden = true })
        }

        break
    }
  }
}