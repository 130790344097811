import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['submitButton']
  static values = { disableWith: String }

  connect() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
      this.originalText = this.submitButtonTarget.innerText
    }
  }

  toggleButton(event) {
    switch (event.detail.state) {
      case 'submitting':
        this.submitButtonTarget.innerText = this.disableWithValue
        this.disableTarget()
        break
      case 'disable':
        this.disableTarget()
        break
      case 'enable':
        this.enableTarget()
        break
      default:
        this.submitButtonTarget.innerText = this.originalText
        this.enableTarget()
    }
  }

  disableTarget() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.add('disabled')
    this.submitButtonTarget.setAttribute('disabled', 'true')
    this.submitButtonTarget.setAttribute('aria-disabled', 'true')
  }

  enableTarget() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.remove('disabled')
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.removeAttribute('aria-disabled')
  }
}
