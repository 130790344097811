import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['externalForm', 'elTriggerChange'];

  connect() {
    this.setExternalForm(this.elTriggerChangeTarget.value);
  }

  onChangeType(e) {
    this.setExternalForm(e.currentTarget.value)
  }

  setExternalForm(selected) {
    if (selected == 'external') {
      this.externalFormTarget.classList.remove('hidden');

      var inputs = this.externalFormTarget.querySelectorAll('input');

      for(var i = 0; i < inputs.length; i++) {
        var $el = inputs[i];

        if ($el.getAttribute('type') != 'file') {
          $el.setAttribute('required', true);
        }
      }
    } else {
      this.externalFormTarget.classList.add('hidden');
      var inputs = this.externalFormTarget.querySelectorAll('input');

      for(var i = 0; i < inputs.length; i++) {
        inputs[i].value = null;
        inputs[i].removeAttribute('required')
      }
    }
  }
}