import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['fraudAlertModal', 'companyName', 'fraudNote']

  launchFraudAlertModal(event) {
    event.preventDefault();
    this.url = event.target.dataset.url;

    axios.get(this.url).then((resp) => {
      this.companyNameTarget.innerHTML = `${resp.data.name}`;
      this.fraudNoteTarget.value = resp.data.fraud_alert_note;

      $(this.fraudAlertModalTarget).modal('show');
    })
  }

  cancel(event) {
    event.preventDefault();

    $(this.fraudAlertModalTarget).modal('hide');
    this.clear();
  }

  submitFraudAlert(event) {
    event.preventDefault();

    if (this.fraudNoteTarget.value === '') {
      alert('Please enter a note for the fraud alert.');
    } else {
      this.submit();
    }
  }

  submit() {
    axios.put(this.url, { fraud_alert_note: this.fraudNoteTarget.value })
      .then((resp) => {
        $(this.fraudAlertModalTarget).modal('hide');

        this.clear();
        this.updateCss({ action: 'addCss', css: 'text-danger', id: `district-${resp.data.id}` });

        toastr.success(`Fraud alert has been created for "${resp.data.name}."`);
      }).catch((_resp) => {
        toastr.error('There was an error creating the fraud alert. Please try again.');
      })
  }

  clear() {
    this.companyNameTarget.innerHTML = '';
    this.fraudNoteTarget.value = '';
  }
}