import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['newForm', 'addBtn']

  connect() {
    this.newFormTarget.hidden = true
  }

  addRecord(event) {
    event.preventDefault()

    this.newFormTarget.hidden = false
    this.addBtnTarget.classList.add('disabled')
  }

  cancel(event) {
    event.preventDefault()

    this.newFormTarget.hidden = true
    this.addBtnTarget.classList.remove('disabled')
  }
}