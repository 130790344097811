// Autopopulate location dropdowns e.g. Country -> State -> City
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['country', 'state', 'city']

  updateState() {
    this.disableSelects()
    this.cityTarget.innerHTML = this.defaultOption()

    this.getStates()
  }

  updateCity() {
    this.disableSelects()
    this.getCities()
  }

  getStates() {
    Rails.ajax({
      url: `/addresses/${this.countryTarget.value}/state_options`,
      type: 'GET',
      contentType: 'application/json',
      success: (response) => {
        this.stateTarget.innerHTML = this.defaultOption() + response.states.map(state => `<option value="${state[0]}">${state[1]}</option>`).join('')
      },
      error: () => { this.stateTarget.innerHTML = this.defaultOption() },
      complete: () => { this.enableSelects() }
    })
  }

  getCities() {
    Rails.ajax({
      url: `/addresses/${this.stateTarget.value}/city_options`,
      type: 'GET',
      contentType: 'application/json',
      success: (response) => {
        this.cityTarget.innerHTML = this.defaultOption() + response.cities.map(city => `<option value="${city}">${city}</option>`).join('')
      },
      error: () => { this.cityTarget.innerHTML = this.defaultOption() },
      complete: () => { this.enableSelects() }
    })
  }

  defaultOption() {
    return `<option value="">All</option>`
  }

  disableSelects() {
    this.stateTarget.disabled = true
    this.cityTarget.disabled = true
  }

  enableSelects() {
    this.stateTarget.disabled = false
    this.cityTarget.disabled = false
  }
}