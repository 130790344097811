import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['address', 'tags', 'templateTag'];

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget,
      {
        fields: ['address_components'],
        types: ['address']
      }
    )

    var self = this;

    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      var cityState = self.getComponentCityState(place.address_components);
      if (cityState) { self.addTags(cityState) };
      self.addressTarget.value = null;
    })
  }

  getComponentCityState(components) {
    var cityState = [];

    var state = components.find(function(c) { 
      return c.types.includes('administrative_area_level_1')
    });

    if (state) { cityState.push(state.long_name) }
    
    var city = components.find(function(c) { 
      return c.types.includes('locality')
    });

    if (city) { cityState.push(city.long_name) }

    return cityState.join(', ')
  }

  addTags(stateCity) {
    var inputName = this.templateTagTarget.getAttribute('name');
    var templateTag = `<div class='badge tag-badge'>
      {{text}}
      <a href='#'>
        <i class='fa fa-times'></i>
      </a>
      <input type='hidden' name='{{inputName}}' value='{{text}}'>
    </div>`
    
    var templateTagWithText = templateTag.replaceAll("{{text}}", stateCity).replace("{{inputName}}", inputName);
    this.tagsTarget.innerHTML = this.tagsTarget.innerHTML + templateTagWithText;
    this.addEventToRemoveTag();
  }

  addEventToRemoveTag() {
    var removeTags = this.tagsTarget.querySelectorAll('a');   

    for(var i = 0; i < removeTags.length; i++) {
      removeTags[i].addEventListener('click', this.onRemoveTag);
    }
  }

  onRemoveTag(e) {
    e.currentTarget.parentElement.remove()
  }
}