import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = { messages: Object }

  connect() {
    const messageTypes = Object.keys(this.messages)

    _.each(messageTypes, (type) => {
      _.each(this.messages[type], (message) => {
        toastr[type](message)
      })
    })
  }

  get messages() {
    return this.messagesValue;
  }
}