import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  cssActions = { create: 'addCss', destroy: 'removeCss' }

  update(event) {
    if (event.detail.id !== this.element.dataset.id) { return; }

    if (event.detail.action === this.cssActions.create) {
      this.addCss(event.detail.css)
    } else if (event.detail.action === this.cssActions.destroy) {
      this.removeCss(event.detail.css)
    }
  }

  addCss(css) {
    if (this.element.classList.contains(css)) { return; }

    this.element.classList.add(css)
  }

  removeCss(css) {
    if (!this.element.classList.contains(css)) { return; }

    this.element.classList.remove(css)
  }
}