import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['template', 'addQuestionButton']
  static values  = { maxQuestionCount: Number, currentQuestionCount: Number }

  connect() {
    this.questionIndexStore = {}
  }

  addQuestion(event) {
    event.preventDefault()

    const questionIndex = Math.floor(Math.random() * 100000)
    const newQuestion   = this.templateTarget.innerHTML.replace(/QuestionIndex/g, questionIndex)

    event.target.parentElement.insertAdjacentHTML('beforebegin', newQuestion)
    this.questionIndexStore[questionIndex] = { formId: `#new-question-form-${questionIndex}`, destroyFieldId: `#new-question-hidden-field-${questionIndex}` }

    this.currentQuestionCountValue += 1
  }

  removeQuestion(event) {
    event.preventDefault()

    const questionIndex = this.questionIndexStore[event.target.dataset.questionIndex]
    const questionId    = event.target.dataset.questionId
    const form          = questionId === '' ? document.querySelector(`${questionIndex.formId}`) : document.querySelector(`#edit-question-form-${questionId}`)
    const destroyField  = questionId === '' ? document.querySelector(`${questionIndex.destroyFieldId}`) : document.querySelector(`#edit-question-hidden-field-${questionId}`)
    form.hidden         = true
    destroyField.value  = true

    this.currentQuestionCountValue -= 1
  }

  setQuestionType(event) {
    const type = event.target.value
    const url  = new URL(window.location.href)
    url.search = `?question_type=${type}`

    window.location.href = url.href
  }

  currentQuestionCountValueChanged(newValue, _oldValue) {
    if (!this.hasAddQuestionButtonTarget) { return }

    if (newValue > this.maxQuestionCountValue) {
      this.addQuestionButtonTarget.classList.add('disabled')
    } else {
      this.addQuestionButtonTarget.classList.remove('disabled')
    }
  }
}
