import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['download', 'upload']

  connect() {
    this.uploadTarget.hidden = true
  }

  downloadComplete() {
    this.uploadTarget.hidden = false

    if (this.hasDownloadTarget) { this.downloadTarget.hidden = true }
  }

  uploadComplete() {
    window.dispatchEvent(this.stateChangedEvent('ready'))
  }

  stateChangedEvent(state) {
    const detail = { state: state, type: 'manual' }

    return new CustomEvent('signatureStateChanged', { detail: detail })
  }
}