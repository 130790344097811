import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['policyValidation', 'submitButton', 'inactiveButton', 'validCarrier', 'form', 'agentError', 'policyError']

  connect() {
    this.validatePolicy()
    this.policyValidationTargets.forEach((target) => {
      target.addEventListener('change', this.validatePolicy.bind(this))
    })
  }

  policyAcknowledge(event) {
    if (event.target.checked) {
      this.acknowledged.policy = true
      this.policyErrorTarget.hidden = true
    } else {
      this.acknowledged.policy = false
      this.policyErrorTarget.hidden = false
    }
  }

  agentAcknowledge(event) {
    if (event.target.checked) {
      this.acknowledged.agent = true
      this.agentErrorTarget.hidden = true
    } else {
      this.acknowledged.agent = false
      this.agentErrorTarget.hidden = false
    }
  }

  validatePolicy(_event) {
    this.isValid = !this.anyInvalid()
    this.inactiveButtonTarget.disabled = this.anyInvalid()
  }

  anyInvalid() {
    return _.some(this.policyValidationTargets, (target) => { return target.checked })
  }

  acknowledged() {
    return (this.acknowledged.agent === true && this.acknowledged.policy === true)
  }

  submit(event) {
    event.preventDefault()

    this.validCarrierTarget.value = this.isValid
    this.submitForm()
  }

  submitInactive(event) {
    event.preventDefault()

    this.validCarrierTarget.value = false
    this.submitForm()
  }

  submitForm() {
    if (this.acknowledged()) {
      this.formTarget.submit()
    } else {
      alert('Please acknowledge the Agent and Policy Information first.')
    }
  }
}