import ApplicationController from "./application_controller";
import { ConfirmationModal } from '../utils/confirmation_modal';

export default class extends ApplicationController {
  connect() {
    this.confirmModal = new ConfirmationModal({ title: 'Save the vehicle?', text: 'You will be charged $99 for submitting the new vehicle.' });
  }

  onSubmitForm(e) {
    e.preventDefault();

    var _this = this;

    this.confirmModal.show().then((result) => {
      if (result.value) { _this.element.closest('form').submit(); }
    })
  }
}