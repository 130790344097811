import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['networkOptions', 'formNetworkAssign'];

  connect() {
    $("[data-assign-network-target]").on('ajax:success', function(e, data, status, xhr){
      $('#js-modalNetworkAssignee').modal('hide');
      toastr.success("Company assigned to network successfully");
      window.location.reload();
    }).on('ajax:error',function(e, xhr, status, error){
      toastr.error("Failed");
    });  
  }

  launchAssignToNetworkModal(event) {
    var companyID = event.currentTarget.getAttribute('data-company-id');
    var url = Routes.populate_network_options_super_admin_company_path({ id: companyID, format: 'json' })
    var submitUrl = Routes.assign_to_network_super_admin_company_path({ id: companyID, format: 'js' })
    var self = this;
    $('#js-modalNetworkAssignee').modal('show');
    
    axios.get(url).then((resp) => {
      var options = self.networkOptionsTarget.querySelectorAll('option');

      options.forEach(function(i) {
        i.remove();
      });

      resp.data.forEach(function(item, index) {
        self.networkOptionsTarget.options[index] = new Option(item[1], item[0]);
      })

      self.formNetworkAssignTarget.setAttribute('action', submitUrl);
    })
  }

  submit() {
    // axios.patch(submitUrl, { fraud_alert_note: this.fraudNoteTarget.value })
    //   .then((resp) => {
    //     $(this.fraudAlertModalTarget).modal('hide');

    //     this.clear();
    //     this.updateCss({ action: 'addCss', css: 'text-danger', id: `district-${resp.data.id}` });

    //     toastr.success(`Fraud alert has been created for "${resp.data.name}."`);
    //   }).catch((_resp) => {
    //     toastr.error('There was an error creating the fraud alert. Please try again.');
    //   })
  }
}