import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'workflowBuilder',
    'workflowName'
  ]

  submitForm(event) {
    event.preventDefault()

    this.workflowBuilderTarget.dispatchEvent(this.customEvent(event))
  }

  pathname(url) {
    const urlObject = new URL(url)

    return urlObject.pathname
  }

  eventDetail(event) {
    return {
      form_id: event.target.form.id,
      form_method: event.target.form.dataset.method,
      form_url: this.pathname(event.target.form.action),
      form_data: { workflow_name: this.hasWorkflowNameTarget? this.workflowNameTarget.value : null }
    }
  }

  customEvent(event) {
    return new CustomEvent('formSubmitted', { detail: this.eventDetail(event) })
  }
}