import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';
import adapter from 'webrtc-adapter'; // shim for cross-browser compatibility

import '../styles/custom/media_capture.scss';

export default class extends ApplicationController {
  static targets = ['video', 'canvas', 'image', 'snapShotButton', 'retakeButton', 'okButton', 'error']
  static values = { imagePlaceholder: String }

  connect() {
    this.videoDimensions = { width: 320, height: 240 }
    this.imageData = null
    this.videoTarget.setAttribute('width', this.videoDimensions.width)
    this.videoTarget.setAttribute('height', this.videoDimensions.height)
    this.snapShotButtonTarget.disabled = true
    this.retakeButtonTarget.disabled = true
    this.okButtonTarget.disabled = true

    this.videoTarget.addEventListener('canplay', _event => {
      let height = this.videoTarget.videoHeight / (this.videoTarget.videoWidth / this.videoDimensions.width)

      if (isNaN(height)) {
        height = this.videoDimensions.width / (4 / 3)
      }

      this.snapShotButtonTarget.disabled = false
      this.videoTarget.setAttribute('width', this.videoDimensions.width)
      this.videoTarget.setAttribute('height', height)
      this.canvasTarget.setAttribute('width', this.videoDimensions.width)
      this.canvasTarget.setAttribute('height', height)
    });
  }

  start(event) {
    event.preventDefault()
    this.stream()
  }

  stream() {
    navigator.mediaDevices.getUserMedia({ video: true, facingMode: 'environment' })
      .then(stream => {
        this.errorTarget.hidden = true
        this.videoTarget.srcObject = stream
        this.videoTarget.play()
      })
      .catch(_err => { this.imageData = null });
  }

  stopStream() {
    this.videoTarget.srcObject.getVideoTracks().forEach(track => track.stop())
  }

  snapShot() {
    this.canvasTarget.getContext('2d').drawImage(this.videoTarget, 0, 0, this.videoDimensions.width, this.videoDimensions.height)
    this.imageData = this.canvasTarget.toDataURL('image/jpeg')

    this.snapShotButtonTarget.disabled = true
    this.retakeButtonTarget.disabled = !this.snapShotButtonTarget.disabled
    this.okButtonTarget.disabled = !this.snapShotButtonTarget.disabled

    this.stopStream()
  }

  retake() {
    this.imageData = null
    this.snapShotButtonTarget.disabled = false
    this.okButtonTarget.disabled = true
    this.retakeButtonTarget.disabled = !this.snapShotButtonTarget.disabled
    globalEventBus.publishEvent(GlobalEvents.imageCapture.removed, { image: this.imageData })

    this.stream()
  }

  acceptImage() {
    this.okButtonTarget.disabled = true
    this.snapShotButtonTarget.disabled = true
    this.stopStream()

    globalEventBus.publishEvent(GlobalEvents.imageCapture.captured, { image: this.imageData })
  }
}