import ApplicationController from "./application_controller";

// https://atomiks.github.io/tippyjs/
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

export default class extends ApplicationController {
  static targets = ['template', 'element']
  static values = { theme: String }

  initialize() {
    tippy(this.elementTarget, {
      content: this.content(),
      allowHTML: true,
      theme: this.theme()
    })
  }

  theme() {
    return this.hasThemeValue ? this.themeValue : null
  }

  content() {
    const container = document.createElement('div')

    if (this.hasTemplateTarget) {
      container.appendChild(document.importNode(this.templateTarget.content, true))
    } else {
      container.innerHTML = this.element.dataset.tooltipContent
    }

    return container.innerHTML
  }
}
