export class FilePreviewer {
  constructor(file, container) {
    this.container = container;
    this.file = file;
  }

  preview() {
    const reader = new FileReader();

    reader.onload = (event) => {
      this.container.src = event.target.result
    }

    reader.readAsDataURL(this.file)
  }
}