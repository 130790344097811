import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { id: Number }

  toggle_validation(event) {
    axios.put(Routes.super_admin_email_domain_path(this.idValue), { validated: event.target.value })
      .then(resp => {
        window.location.reload()
        toastr.success(resp.data.message)
      })
      .catch(_err => { toastr.error('There was an error updating the validation status') })
  }
}