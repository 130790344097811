import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['passingStrategyField', 'passMarkField']

  setPassMarkMax(event) {
    if (/percentage/.test(event.target.value)) {
      this.passMarkFieldTarget.setAttribute('max', '100')
      this.passMarkFieldTarget.value = 50
    } else {
      this.passMarkFieldTarget.setAttribute('max', '10')
      this.passMarkFieldTarget.value = 5
    }
  }
}