import ApplicationController from "./application_controller";
import axios from '../utils/axios';

export default class extends ApplicationController {
  static values = { url: String }
  static targets = ['verificationNote']

  setInActive(event) {
    event.preventDefault()
    this.submitting()

    this.active = false
    this.submitForm()
  }

  setActive(event) {
    event.preventDefault()
    this.submitting()

    this.active = true
    this.submitForm()
  }

  submitForm() {
    axios.put(this.urlValue, { active: this.active, verification_note: this.verificationNoteTarget.value })
      .then((resp) => {
        toastr.success(resp.data.success)
        $(this.element).modal('hide')

        // Hack to reload the page after the toast is displayed
        setTimeout(() => {
          location.reload()
        }, 1000)
      })
      .catch((error) => { toastr.error(error.response.data.error) })
      .finally((_resp) => { this.submitted() })
  }
}