import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['objectType', 'objectID', 'index', 'districtID', 'editForm', 'actionButton'];

  connect () {
  }

  onArchiveNote () {
    var self = this;

    Swal({
      title: 'Are you sure?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33'
    }).then(function(result) {
      if (result.value) {
        axios.delete(Routes.destroy_notes_path(self.objectTypeTarget.value, self.objectIDTarget.value, self.indexTarget.value, { format: 'json', district_id: self.districtIDTarget.value }))
          .then(function(response) {
            self.element.remove();
            window.location.reload()
          });
      }
    });
  }

  onSubmitEdit () {
    var self = this;
    
    if (this.editFormTarget.querySelector('textarea') && this.editFormTarget.querySelector('textarea').value) {
      axios.put(Routes.update_notes_path(self.objectTypeTarget.value, self.objectIDTarget.value, self.indexTarget.value, { format: 'json', district_id: self.districtIDTarget.value }), { note: this.editFormTarget.querySelector('textarea').value })
        .then(function(response) {
          window.location.reload()
        });
    }
  }

  onClickEditNote () {
    this.editFormTarget.classList.remove('hidden');
    this.actionButtonTarget.classList.add('hidden');
  }

  onCancelEdit () {
    this.editFormTarget.classList.add('hidden');
    this.actionButtonTarget.classList.remove('hidden');
  }
}