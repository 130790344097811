import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['code', 'codeVerifications', 'counter', 'sendAgain', 'countdownTime']

  connect() {
    this.codeTarget.hidden = true
    this.codeVerificationsTarget.hidden = false
    this.counterTarget.hidden = true
    this.urls = {
      email: `/user_verification/email`,
      sms: `/user_verification/sms`
    }
  }

  generateVerificationCode(event) {
    const verificationType = event.target.dataset.verificationType

    Rails.ajax({
      url: this.urls[verificationType],
      type: 'POST',
      data: new URLSearchParams().toString(),
      success: (resp) => {
        toastr.success(`Verification code sent thru ${verificationType}`)

        this.showCodeInput()

        if (verificationType === 'sms') {
          this.counterTarget.hidden = false
          this.countdown()
        } else {
          this.counterTarget.hidden = true
        }
      },
      error: (resp) => {
        toastr.error(resp.error)
      }
    })
  }

  showCodeInput() {
    this.codeTarget.hidden = false
    this.codeVerificationsTarget.hidden = true
  }

  regenCode(event) {
    event.preventDefault()

    this.codeTarget.hidden = true
    this.codeVerificationsTarget.hidden = false

    if (this.seconds >= 0) {
      this.counterTarget.hidden = false
      this.seconds = 0
    }
  }

  countdown() {
    this.countdownTimeTarget.innerHTML = '01:00'
    this.sendAgainTarget.classList.toggle('disabled')
    this.seconds = 60

    const interval = setInterval(() => {
      if (this.seconds > 0) { this.seconds-- }

      this.countdownTimeTarget.innerHTML = "0:" + (this.seconds < 10 ? "0" : "") + String(this.seconds)

      if (this.seconds <= 0) {
        clearInterval(interval)

        this.counterTarget.hidden = true
        this.sendAgainTarget.classList.toggle('disabled')
      }
    }, 1000)
  }
}