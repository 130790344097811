import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['uploadBy', 'file', 'existingCompanyUpload']
  static values = { url: String, fileType: String }

  connect() {
    this.setUploadForm()
  }

  setUploadForm() {
    if (this.uploadByTarget.value == 'company_upload') {
      this.showToggleableElement()
    } else {
      this.hideToggleableElement()
      this.fileTarget.value = ''
    }
  }

  deleteExistingUpload() {
    if (confirm('Are you sure you want to delete this upload?')) {
      Rails.ajax({
        type: 'DELETE',
        url: `${this.urlValue}`,
        contentType: 'application/json',
        data: new URLSearchParams({ file_type: this.fileTypeValue }).toString(),
        success: (_resp) => { this.existingCompanyUploadTarget.hidden = true },
        error: (_resp) => { console.table.response }
      })
    }
  }
}
