import ApplicationController from './application_controller';
import axios from '../utils/axios';
import { ConfirmationModal } from '../utils/confirmation_modal';

export default class extends ApplicationController {
  static targets = ['switch', 'label']
  static values = { labels: Object, url: String, confirm: String }

  connect() {
    if (!this.hasLabelsValue) {
      this.labelsValue = { on: 'Enabled', off: 'Disabled' }
    }
    this.showConfirmModal = this.hasConfirmValue && this.confirmValue.length > 0

    this.toggleLabel()
  }

  doToggle(event) {
    if (this.showConfirmModal) {
      const modal = new ConfirmationModal({ text: this.confirmValue })

      modal.show().then(result => {
        if (result.value) {
          this.sendRequest(event)
        } else {
          this.reset(event)
        }
      })
    } else {
      this.sendRequest(event)
    }
  }

  sendRequest(event) {
    const payload = { [event.target.name]: event.target.checked }

    axios.put(this.urlValue, payload)
      .then(resp => {
        const successMsg = resp.data.message || 'Update successful.'
        toastr.success(successMsg)
      })
      .catch(resp => {
        this.reset(event)

        const errorMsg = resp.response.data.message || 'Update failed. Please try again.'
        toastr.error(errorMsg)
      })
      .finally(() => { this.toggleLabel() })
  }

  reset(event) {
    event.target.checked = !event.target.checked
  }

  toggleLabel() {
    try {
      if (this.switchTarget.checked) {
        this.labelTarget.innerHTML = this.labelsValue.on
      } else {
        this.labelTarget.innerHTML = this.labelsValue.off
      }
    } catch (err) {
      // toggle without label
    }
  }
}