import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';

export default class extends ApplicationController {
  static targets = ['switch', 'label']
  static values = { labels: Object }

  connect() {
    globalEventBus.subscribe(GlobalEvents.toggle.label, this.handleToggleLabelEvent.bind(this));

    if (!this.hasLabelsValue) {
      this.labelsValue = { on: 'Enabled', off: 'Disabled' };
    }

    this.toggleLabel();
  }

  toggleLabel() {
    if (!this.labelsValue) { return };
    
    if (this.switchTarget.checked) {
      this.labelTarget.innerHTML = this.labelsValue.on;
    } else {
      this.labelTarget.innerHTML = this.labelsValue.off;
    }
  }

  handleToggleLabelEvent(event) {
    if (event.target.id === this.switchTarget.id) { this.toggleLabel(); }
  }
}