import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['documentVisibilityButton']
  
  connect() {
    this.mainAttributes = JSON.parse(this.element.dataset.attributes);
    this.source = this.element.dataset.source;
    this.initUI();
  }

  toggleDocumentVisibility() {
    var _this = this;
    this.updatingButtonUI();
    var updateAttributes = {};
    updateAttributes[this.source] = {};
    updateAttributes[this.source][this.element.dataset.class] = {
      document_visibility: !this.mainAttributes.document_visibility
    };
    
    axios.put(this.element.dataset.url, updateAttributes)
      .then((resp) => {
        _this.mainAttributes.document_visibility = !_this.mainAttributes.document_visibility;
        _this.initUI();
      })
      .catch((error) => { toastr.error(error.response.data.error) })
      .finally((_resp) => { this.submitted() })
  }

  initUI() {
    this.initButtonUI();
  }

  updatingButtonUI() {
    this.resetButtonUI();
    this.documentVisibilityButtonTarget.innerText = 'Updating ...';
    this.documentVisibilityButtonTarget.disabled = true;
  }

  initButtonUI() {
    this.resetButtonUI();

    this.documentVisibilityButtonTarget.classList.add('hover:bg-blue-50');
    this.documentVisibilityButtonTarget.classList.add('text-blue-300');
    this.documentVisibilityButtonTarget.classList.add('bg-blue-50');
    this.documentVisibilityButtonTarget.innerText = 'Hide';

    if (!this.mainAttributes.document_visibility) {
      this.documentVisibilityButtonTarget.classList.add('hover:bg-gray-100');
      this.documentVisibilityButtonTarget.classList.add('text-gray-500');
      this.documentVisibilityButtonTarget.classList.add('bg-gray-100');
      this.documentVisibilityButtonTarget.innerText = 'Make Visibile';
    }
  }

  resetButtonUI() {
    this.documentVisibilityButtonTarget.classList.remove('hover:bg-blue-50');
    this.documentVisibilityButtonTarget.classList.remove('text-blue-300');
    this.documentVisibilityButtonTarget.classList.remove('bg-blue-50');

    this.documentVisibilityButtonTarget.classList.remove('hover:bg-gray-100');
    this.documentVisibilityButtonTarget.classList.remove('text-gray-500');
    this.documentVisibilityButtonTarget.classList.remove('bg-gray-100');

    this.documentVisibilityButtonTarget.disabled = false;
  }
}