import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['targetEmail', 'shouldSend', 'sendSchedule', 'submitButton']

  connect() {
    this.enableOptions()
  }

  enableOptions() {
    if (this.shouldSendTarget.checked) {
      this.toggleOptions(false)
    } else {
      this.toggleOptions(true)
    }
  }

  toggleOptions(yes_or_no) {
    this.sendScheduleTargets.forEach(element => {
      element.disabled = yes_or_no
    })

    this.targetEmailTarget.disabled = yes_or_no
  }

  selectedSendSchedule() {
    const selected = _.find(this.sendScheduleTargets, (target) => { return target.checked })

    return selected.value
  }

  requestOptions(recordId) {
    if (recordId !== undefined) {
      return { url: `/company_admin/email_subscriptions/${recordId}`, type: 'PUT' }
    } else {
      return { url: '/company_admin/email_subscriptions', type: 'POST' }
    }
  }

  submit(event) {
    this.submitting()

    const request = this.requestOptions(event.target.dataset.id)
    const data = {
      target_email: this.targetEmailTarget.value,
      should_send: this.shouldSendTarget.checked,
      subscription_for: event.target.dataset.subscriptionFor,
      send_schedule: this.selectedSendSchedule()
    }

    Rails.ajax({
      type: request.type,
      url: request.url,
      contentType: 'application/json',
      data: new URLSearchParams(data).toString(),
      success: (resp) => { toastr.success(resp.message) },
      error: (resp) => { toastr.error(resp.error) },
      complete: () => { this.submitted() }
    })
  }
}