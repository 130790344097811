import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['companyName', 'companyState', 'companyCity', 'coiStatus', 'networkStatus', 'confirmRemoveNetworkModal', 'sendEmailModal', 'subjectEmail', 'messageEmail']

  connect() {
    $(this.confirmRemoveNetworkModalTarget).on('show.bs.modal', (event) => {
      const button = $(event.relatedTarget)
      this.networkId = button.data('network-id')
    })

    $(this.sendEmailModalTarget).on('show.bs.modal', (event) => {
      const button = $(event.relatedTarget)
      this.networkId = button.data('network-id')
    })
  }

  downloadCsv(event) {
    event.preventDefault()
    this.submitting()

    const url = new URL(event.target.href)

    url.searchParams.set('company_name', this.companyNameTarget.value)
    url.searchParams.set('state', this.companyStateTarget.value)
    url.searchParams.set('city', this.companyCityTarget.value)
    url.searchParams.set('coi_status', this.coiStatusTarget.value)
    url.searchParams.set('network_status', this.networkStatusTarget.value)

    window.location.href = url
    this.submitted()
  }

  confirmRemoveNetwork(event) {
    event.preventDefault()

    $.ajax({
      type: 'DELETE',
      url: `/districts/${this.networkId}/remove_association`,
      dataType: 'json',
      success: (_resp) => { toastr.success('Company has been removed from your network') },
      error: (_resp) => { toastr.error('Request can not be processed') },
      complete: () => {
        $(this.confirmRemoveNetworkModalTarget).modal('hide')
        window.location.reload()
      }
    })
  }

  rejectRemoveNetwork(event) {
    event.preventDefault()

    $.ajax({
      type: 'POST',
      url: `/networks/${this.networkId}/reject_deletion`,
      dataType: 'json',
      success: (_resp) => { toastr.success('Request network deletion has been rejected') },
      error: (_resp) => { toastr.error('Request can not be processed') },
      complete: () => {
        $(this.confirmRemoveNetworkModalTarget).modal('hide')
        window.location.reload()
      }
    })
  }

  sendEmail(event) {
    event.preventDefault()

    $.ajax({
      type: 'POST',
      url: `/networks/${this.networkId}/send_email`,
      data: {
        subject: this.subjectEmailTarget.value,
        message: this.messageEmailTarget.value
      },
      dataType: 'json',
      success: (_resp) => { toastr.success('Email has been sent successfully') },
      error: (_resp) => { toastr.error('Request can not be processed') },
      complete: () => {
        $(this.sendEmailModalTarget).modal('hide')
        this.subjectEmailTarget.value = ''
        this.messageEmailTarget.value = ''
      }
    })
  }
}