import ApplicationController from "./application_controller";
import { usingMobile } from "../utils/mobile_detection";

export default class extends ApplicationController {
  static targets = ['mobileDocumentView', 'webDocumentView']
  static values = { isPdf: Boolean }

  connect() {
    this.mobileMode = usingMobile() && this.isPdfValue
    this.mobileDocumentViewTarget.hidden = !this.mobileMode
    this.webDocumentViewTarget.hidden = this.mobileMode
  }
}