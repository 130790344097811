import ApplicationController from './application_controller';
import axios from '../utils/axios';;
// import xml2js from 'xml2js';

export default class extends ApplicationController {
  onSubmitForm(e) {
    e.preventDefault();

    var file = this.element.querySelector('input[type="file"]').files[0];
    var _self = this;
    
    axios
      .post(this.element.action, { filename: file.name })
      .then(function(response) {
        if (response && response.data && response.data.url) {
          _self.uploadToS3(response.data, file);
        } else {
          toastr.error("Presigner couldn't be fetched")
        }
      })
  }

  uploadToS3(uploadParams, file) {
    var url = uploadParams.url;
    var fields = uploadParams.s3_upload_params;
    var filename = uploadParams.filename;
    var action = this.element.action;
    
    // create new form object to uplaod the file
    const formData = new FormData();

    // Append S3 upload params to the form object.
    if (fields) {
      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v);
      });

    }
    formData.append("file", file, filename);
    $('.Video-upload').addClass('uploading');
    function confirmExit() {
        return "Uploading the vidoe it's still inprogress, please don't close the browser.";
    }

    window.onbeforeunload = confirmExit;
    if (fields) {

      var settings = {
        "url": url,
        "method": "POST",
        "timeout": 0,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        "data": formData,
        success: function() {
          window.onbeforeunload = null;

          axios
            .put(action, { file: filename })
            .then(function(response) {
              if (response.data.uploaded) {
                window.location.href = response.data.callback;
              } else {
                toastr.error("Video couldn't be uploaded");
              }
            })
            .catch(function(err) {
              console.log(err);
              toastr.error("Something wen't wrong, e: " + err.message);
            });
        },
        error: function(err) { 
          window.onbeforeunload = null;
          console.log(err);
          toastr.error("Something wen't wrong, e: " + err.statusText);
        }
      };

      $.ajax(settings)
    } else {
      axios
        .put(this.element.action, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(function(response) {
          window.onbeforeunload = null;
          if (response.data.uploaded) {
            toastr.success("Video is successfully uploaded");
            window.location.href = response.data.callback;
          } else {
            toastr.error("Video couldn't be uploaded");
          }
        })
        .catch(function(err) {
          window.onbeforeunload = null;
          toastr.error("Something wen't wrong, e: " + err.message);
        });
    }

  }

  selectVideo(e) {
    $(e.currentTarget).siblings('input').click();
  }

  onSelectVideo(e) {
    $('.Video-upload a').html('Video selected, please click this again to change and <br>click save to upload the video');
  }
}