// make a checkbox group act like a radio group
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['checkbox']

  connect() {
    const checkedElement = this.getChecked()
    this.toggleOthers({ target: checkedElement })
  }

  toggleOthers(event) {
    const checkboxElement = event.target

    this.checkboxTargets.forEach(checkbox => {
      if (checkbox !== checkboxElement) {
        checkbox.checked = false
        checkbox.dispatchEvent(new CustomEvent('change'));
      }
    })
  }

  getChecked() {
    return _.find(this.checkboxTargets, checkbox => checkbox.checked)
  }
}